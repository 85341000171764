class CustomSongClient {
  constructor(sendFlow) {
    this.el = sendFlow.el;
    this.sendFlow = sendFlow;
    this.reportingString = "";
    this.beaconQueued = false;
    this.triggerElement = null;
    this.onComplete = (songfinchResponse) => {};
    this.init();
  }

  init() {
    this.client = new SongfinchClient(this.getCustomSongConfig());
    this.client.init();
    this.client.on("modal-opening", () => {
      window.app_page.freezeScroll(true, false, 300)
    })
    this.client.on("modal-closing", () => {
      !window.app_dialogs.activeDialogs.size && window.app_page.setPageHidden(false)
    })
    this.client.on("modal-closed", () => {
      !window.app_dialogs.activeDialogs.size && window.app_page.unfreezeScroll()
      this.triggerElement?.focus();
    })
    this.client.on("report-updated", (reportingString) => {
      if (!reportingString || reportingString === this.reportingString) return
      this.beaconQueued = false;
      this.reportingString = reportingString;
    })

    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        this.sendBeacon()
      }
    })

    this.client.on("song-customization-finished", (songfinchResponse) => {
      if (typeof this.onComplete !== "function") return
      this.onComplete(songfinchResponse)
    });
    this.appendReportingHistory();
  }

  open(triggerElement) {
    triggerElement = triggerElement || document.activeElement;
    if (triggerElement?.classList.contains("dialog__close")) {
      // override trigger element if the adding was performed from gift info modal
      const selector = `button[data-digital-gift-action="add"][value="custom-song"]`;
      let newTrigger = null;
      if (window.app_dialogs.activeDialogs.has("gift-options")) {
        const giftDialog = window.app_dialogs.collection.get("gift-options");
        newTrigger = giftDialog.el.querySelector(selector);
      } else {
        const sendForm = window.app_forms.collection.get("email-send");
        newTrigger = sendForm.el.querySelector(selector)
      }
      triggerElement = newTrigger || triggerElement;
    }

    this.triggerElement = triggerElement;
    this.client.open({returningEl: this.triggerElement})
  }

  async appendReportingHistory() {
    const endpoint = new URL(`${app_env.apihost}/simple/miscusage`);
    endpoint.searchParams.append("client_id", app_env.client_id);
    endpoint.searchParams.append("customer_number", app_env.customer?.number || 0);
    endpoint.searchParams.append("nav_type", `send/${app_env.ecard_order.id || 1}`);
    endpoint.searchParams.append("product_number", app_env.product_config?.custom_song?.merch_number);
    try {
      const request = await fetch(endpoint);
      const response = await request.json();
      if (response?.item?.navigation) {
        const currentUsage = this.client.getUsage()
        this.client.setUsage({
          ...currentUsage,
          reporting: response.item.navigation + currentUsage.reporting
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  report(eventName) {
    if(!eventName) return
    this.client?.emit("report-record", eventName)
  }

  sendBeacon() {
    if (this.beaconQueued || this.reportingString === "") return
    const payload = {
      customer_number: app_env.customer?.number || 0,
      nav_type: `send/${app_env.ecard_order.id || 1}`,
      product_number: app_env.product_config?.custom_song?.merch_number,
      navigation: this.reportingString
    }

    this.beaconQueued = navigator.sendBeacon(
      `${app_env.apihost}/simple/miscusage?client_id=${app_env.client_id}`,
      new URLSearchParams(payload)
    )
  }

  getCustomSongConfig() {
    return {
      service: {
        clientId: app_env.client_id,
        baseUrl: app_env.apihost,
        collectionId: app_env.product_config?.custom_song?.collection_id || [],
        customerEmail: app_env.customer?.email || "",
        extraParams: {
          jwt_auth: app_env.product_config?.custom_song?.jwt_auth
        }
      },
      ui: {
        strings: {
          song_price: `$${app_env.checkout_price}`,
        }
      },
      options: {
        reporting: true,
        managedScrollFreeze: true,
      }
    }
  }
}

export default CustomSongClient;