import Preview from "./Preview";
import Utility from "../../../base/Utility";

class TalkingSmashupPreview extends Preview {

  constructor(el, product, pers) {
    super(el, product, pers);
    this.hasVideo = true;
    this.type = 'talking-smashup';
    this.productHasReplay = false;
    this.productReplayHandler = () => { };
  }

  get productExpression() {
    return window.agi?.expressions?.ExpressionManager?.expression
  }

  get productExpressor() {
    return window.agi?.expressions?.Expressor?.prototype
  }

  get productExpress() {
    return window.agi?.expressions?.Express
  }

  afterInit() {
    this.isReady = true;
    this.loadProduct();
    this.watchLoader();
  }

  watchLoader() {
    this.el.classList.remove('--ready', '--after-ready');
    this.el.classList.add('--waits-visible');
    Utility.elementRendered('#agi-expression-container', this.container).then((el) => {
      Utility.elementRendered('img, canvas', this.container).then((el) => {
        setTimeout(() => {
          this.ready();
        }, 100)
      })
    })
  }

  afterReady() {
    this.productExpress.dispatcher?.addEventListener("playAudio", this.playAudioHandler.bind(this));
    this.replacePlayButton();
    this.addMissingButtonInteractions();
  }

  replacePlayButton() {
    if (this.container.querySelector(".tts-expression-play-button:not(.--started)")) return
    if (!this.productExpression) return
    const button = document.createElement("button");
    button.setAttribute("type", "button");
    button.setAttribute("aria-label", "Play");
    button.classList.add("tts-expression-play-button");
    button.addEventListener("click", (e) => {
      this.productExpression.playButton.visible = false;
      this.productExpression.playButton.dispatchEvent("click");
      button.classList.add("--started");
      setTimeout(() => button.remove(), 300)
    });

    this.container.querySelector("#agi-expression-overlay")?.prepend(button);
    this.productExpression.playButton.alpha = 0;
  }

  addMissingButtonInteractions() {
    // Add missing keyboard interaction and labels to the product buttons
    if (!this.productExpressor || !this.productExpress) return

    const buttonOptions = [
      {
        label: "Mute",
        selector: "#agi-expression-mute-button",
        callback: this.productExpressor.onMuteToggle,
      },
      {
        label: "Closed Captions",
        selector: "#agi-expression-closed-caption-button",
        callback: this.productExpressor.onClosedCaptionButtonToggle,
      },
      {
        label: "Replay",
        selector: "#agi-expression-replay-button",
        callback: () => {
          this.productExpress.dispatcher?.dispatchEvent("replayButtonClicked")
        },
      },
    ]

    buttonOptions.forEach(({ label, selector, callback }) => {
      const button = this.container.querySelector(selector);
      button?.setAttribute("aria-label", label);
      button?.addEventListener("keyup", (event) => {
        const isSpaceOrEnter = ["Space", "Enter"].includes(event.code)
        if (isSpaceOrEnter && typeof callback == "function") {
          callback(event);
        }
      });
    })
  }

  playAudioHandler() {
    if (this.dialog && !this.dialog.isActive) {
      this.resetMedia();
    }
  }

  resetMedia() {
    if (!this.productExpress || !this.productExpression) return
    const ccButton = this.container.querySelector("#agi-expression-closed-caption-button.toggled");
    ccButton?.dispatchEvent(new Event("mouseup"));

    this.productExpression.stop();

    this.productExpress.playbackComplete();
    this.productExpress.stopAudio("music2");
    this.productExpress.musicIsPlaying = false;

    this.productExpression.gotoAndStop("idle");
    this.productExpression.pickupBackground.visible = true;

    this.productExpress.hideMuteButton();
    this.productExpress.hideClosedCaptionButton();
    this.productExpress.hideReplayButton();
    this.replacePlayButton();
  }

}

export default TalkingSmashupPreview;