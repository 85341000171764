import Heartbeat from "@common/modules/utilities/Heartbeat";
import Client from "../../../base/Client";
import Render from "../../../base/Render";
import Utility from "../../../base/Utility";

class GiftCardProvider {
  constructor(config, manager) {
    this.manager = manager;
    this.config = config;
    this.giftOption = config.keyword;
    this.label = config.description;
    this.sendFlow = manager.sendFlow;
    this.init();
  }

  init() {
    this.manager.initGiftProvider(this.config);
    this.initGiftPanel();
  }

  addGiftAttached() {
    this.manager.addGiftAttached(this.giftOption);
  }

  initGiftPanel() {
    let form = this.sendFlow.forms.get("select-gift-form");
    let panel = this.sendFlow.wizard.panels.get("select-gift");
    let iframeHolder = panel?.el.querySelector("#jifiti-merchant");
    if (!panel || !form || !iframeHolder) return
    let jifitiListener = new AbortController();

    let heartbeat = null;
    let iframeString = iframeHolder.innerHTML || "";
    if (this.sendFlow.order.mode == 'send' || this.sendFlow.order.mode == 'edit') {
      this.renderIframeEcardFirst(form, panel, iframeHolder, jifitiListener, heartbeat, iframeString);
    } else {
      this.renderIframeGiftCardFirst(form, panel, heartbeat, iframeHolder);
    }
  }

  renderIframeEcardFirst(form, panel, iframeHolder, jifitiListener, heartbeat, iframeString) {
    // Render iframe before panel activation
    panel.el.addEventListener("panel-before-activation", () => {
      jifitiListener?.abort();
      jifitiListener = new AbortController();
      iframeHolder.innerHTML = "";
      iframeHolder.appendChild(Render.fragmentFromString(iframeString));
      let iframe = iframeHolder.querySelector("iframe");
      let loader = iframeHolder.querySelector(".lazy-load-iframe__loader");
      if (!iframe) return
      iframe.setAttribute("src", this.sendFlow.order.giftcard_iframe);
      iframe.addEventListener("load", () => {
        setTimeout(() => {
          iframeHolder.classList.add("--loaded");
          setTimeout(() => {
            loader.remove();
          }, 300)
        }, 500);
      });
      // Listen for message event
      window.addEventListener('message', e => {
        if (!e.origin.includes("jifiti.com") || e.data?.event != "success") return
        panel.disableNavigation();
        heartbeat?.kill();
        heartbeat = new Heartbeat(30, 15);
        heartbeat.onSuccess = (data) => {
          form.el.submit();
        }
        heartbeat.onFail = (reason) => {
          form.addError("server", "Unable to connect to merchant, please try again later.");
          form.renderErrors();
          panel.enableNavigation();
        }
        heartbeat.start(`${app_env.shost}/gifts/transaction-started/${this.sendFlow.order.id}${this.sendFlow.order.sender_id_fragment}`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      }, { signal: jifitiListener.signal });
    });

    // Clear iframe and errors after deactivation
    panel.el.addEventListener("panel-deactivated", () => {
      jifitiListener?.abort();
      form.clearErrors(["server"]);
      heartbeat?.kill();
      iframeHolder.classList.remove("--loaded");
      iframeHolder.innerHTML = "";
    });
  }

  async renderIframeGiftCardFirst(form, panel, heartbeat, iframeHolder) {
    let loader = iframeHolder.querySelector(".lazy-load-iframe__loader");
    const searchParams = new URL(location.href).searchParams;
    await Utility.resourcesLoaded(
      "jifiti",
      [this.sendFlow.order.script_src],
      false,
      () => (window.GiftitSDK)
    );
    setTimeout(() => {
      iframeHolder.classList.add("--loaded");
      setTimeout(() => {
        loader.remove();
      }, 300)
    }, 500);
    let fullName;
    if (app_env.customer) {
      let firstName = app_env.customer.name;
      let lastName = app_env.customer.lname;
      fullName = firstName.concat(" ", lastName);
    }

    const giftitSDK = new GiftitSDK({
      element: {
        selector: '.gift-container__iframe',
        width: "100%",
        height: "725px",
      },
      partnerId: this.sendFlow.order.partner_id,
      token: this.sendFlow.order.callback_token,
      profileId: this.sendFlow.order.profile_id,
      ecard_id: this.sendFlow.order.id,
      gifter: {
        name: fullName,
        email: app_env.customer.email
      },
      sendDate: new Date(), // Today or some other date.
      store: searchParams.get("store"),
      onFinish(qrCodes) {
        panel.disableNavigation();
        heartbeat?.kill();
        heartbeat = new Heartbeat(30, 15);
        heartbeat.onSuccess = (data) => {
          form.el.submit();
        }
        heartbeat.onFail = (reason) => {
          form.addError("server", "Unable to connect to merchant, please try again later.");
          form.renderErrors();
          panel.enableNavigation();
        }
        heartbeat.start(`${app_env.shost}/gifts/transaction-started/${app_env.ecard_order.id}${app_env.ecard_order.sender_id_fragment}`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Csrftoken': Client.getCSRFToken(),
          }
        });
      }
    })
  }
}

export default GiftCardProvider;