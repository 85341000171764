import Utility from "../../base/Utility";

class FormInteractions {
  constructor(form) {
    this.form = form;
    this.init();
  }

  init() {
    this.addRecaptcha();
    this.dateFieldsetInteraction();
    this.signinInteraction();
    this.expiryDateInteraction();
    this.generateMessageInteraction();
    this.pofUpdateInteraction();
    this.handleSpecialOffer();
    this.processMembershipStarted();
    this.handleCheckoutFlow();
    this.handleAddRecipients();
    this.addRecipientsFromStorage();
  }

  addRecipientsFromStorage() {
    if (!(this.form.id == "register-form" || this.form.id.includes("sign-in") || this.form.id == "join-add-recipients" || this.form.id == "join-form")) return
    let cartRecipients = window.app_storage.get("cartRecipients", "local");
    if (!cartRecipients) return
    if (this.form.requestJSON) {
      this.form.filterRequest = (data) => {
        let recipients = [];
        cartRecipients.forEach(el => {
          recipients.push(el);
        })
        data.recipients = recipients;
        return { ...data }
      };
    } else {
      this.form.fill({"recipients": JSON.stringify(cartRecipients)});
    }
  }

  updatePriceChart(priceChart) {
    priceChart.forEach(row => {
      let sqty = row.sqty;

      if (sqty.search("-") > 0) {
        let quantities = sqty.split('-');
        row["minqty"] = parseInt(quantities[0]);
        row["maxqty"] = parseInt(quantities[1]);
      }
      else if (sqty.indexOf("+") > 0) {
        let quantities = sqty.split('+');
        row["minqty"] = parseInt(quantities[0]);
        row["maxqty"] = 200;
      }
      else {
        row["minqty"] = parseInt(sqty);
        row["maxqty"] = row["minqty"];
      }
    });

    return priceChart
  }

  updateOrderSummary(recipientsTable, priceChart) {
    let basePrice = priceChart[0].unit_price;
    let entriesCount = recipientsTable.entries.length;
    this.form.fill({ "quantity": entriesCount });
    if (entriesCount) {
      priceChart.forEach(row => {
        if (row.minqty <= entriesCount && entriesCount <= row.maxqty) {
          let pricePerUnit = parseFloat(row.unit_price);
          let totalPrice = entriesCount * basePrice;
          let discountedPrice = entriesCount * pricePerUnit;
          let discountPercent = row.percent_savings;
          let discountedAmount = totalPrice - discountedPrice;
          Utility.updateContent({
            "percent_discount": discountPercent,
            "amount_discount": "$" + discountedAmount.toFixed(2),
            "advent-subtotal": "$" + totalPrice.toFixed(2),
            "advent-total": "$" + discountedPrice.toFixed(2),
            "advent-count": "x " + entriesCount,
          });
        }
        else return
      })
    }
  }

  handleAddRecipients() {
    if (this.form.id != "add-recipients" && this.app_env?.page_type != "advent-cart") return
    let priceChart = app_env.price_chart_table;
    if (!priceChart) return
    priceChart = this.updatePriceChart(priceChart);
    Utility.elementRendered('#recipients', this.el).then(() => {
      let recipientsTable = window.app_tables?.collection?.get("recipients");
      if (!recipientsTable) return

      recipientsTable.el.addEventListener("change", () => {
        this.updateOrderSummary(recipientsTable, priceChart)});
    });

    this.form.onBeforeSubmit = () => {
      this.form.filterGetter = (data) => {
        if (data.hasOwnProperty("recipients")) {
          let recipients = [];
          for (const key in data.recipients) {
            recipients.push(data.recipients[key]);
          }
          data.recipients = recipients;
          window.app_storage.set("cartRecipients", recipients, "local");
        }
        return { ...data }
      };
    };
  }

  handleCheckoutFlow() {
    if (this.form.id != "checkout-form") return
    let price_options = this.form.fields?.get("pricechoice")?.fieldset;
    let payment_method = this.form.fields?.get("paymethod")?.fieldset;
    if (!payment_method) return
    payment_method.addEventListener("change", (el) => {
      if (el.target.value == "applepay") {
        Utility.updateContent({
          "apple-payment-legal": {
            removeClass: "hidden",
          },
          "payment-legal-copy": {
            addClass: "hidden",
          },
        });
      }
      else {
        Utility.updateContent({
          "apple-payment-legal": {
            addClass: "hidden",
          },
          "payment-legal-copy": {
            removeClass: "hidden",
          },
        });
      }
    });

    if (!price_options) return
    price_options.addEventListener("change", (el) => {
      if (el.target.id == "single-send-price") {
        let pps_price = "$" + el.target.value;
        Utility.updateContent({
          "summary-title": "Single Send Purchase",
          "pps-total-price": {
            removeClass: "hidden",
          },
          "subscription-purchase": {
            addClass: "hidden",
          },
          "save-pof": {
            removeClass: "hidden",
          },
          "subscription-legal-copy": {
            addClass: "hidden",
          },
          "pay-per-send-submit": "Purchase & Send",
          "applepay-submit": "Pay with",
          "applepay-purchase": `to purchase your pay per send ecard that's billed at ${pps_price}`
        });
        this.form.el.setAttribute("data-endpoint", "self");
      } else {
        let subscription_price = "$" + el.target.getAttribute("data-price");
        let subscription_term = el.target.getAttribute("data-term");
        switch (subscription_term) {
          case "1 month":
            subscription_term = "Monthly Membership Subscription"
            break;
          case "1 year":
            subscription_term = "1 Year Membership Subscription"
            break;
          case "2 years":
            subscription_term = "2 Years Membership Subscription"
            break;
        }
        Utility.updateContent({
          "summary-title": "Ecard Send",
          "pps-total-price": {
            addClass: "hidden",
          },
          "subscription-purchase": {
            removeClass: "hidden"
          },
          "save-pof": {
            addClass: "hidden",
          },
          "subscription-legal-copy": {
            removeClass: "hidden",
          },
          "subscription_price": subscription_price,
          "subscription_term": subscription_term,
          "pay-per-send-submit": "Join Now & Send",
          "applepay-submit": "Subscribe with",
          "applepay-purchase": `to start your ${subscription_term} subscription that's billed at ${subscription_price} for ${el.target.getAttribute("data-term")}`
        });
        this.form.el.setAttribute("data-endpoint", `${window.app_env.phost}/join/payment`);
      }
    })
  }

  handleSpecialOffer() {
    if (this.form.id != "manage-membership") return

    this.form.onAfterSuccess = (form) => {
      let save_offer = form.lastRequest.response?.data;
      if (!save_offer) return
      if (save_offer.auto_renew) {
        if(save_offer.auto_renew == 'Y') {
          Utility.updateContent({
            "auto-renew-copy": "automatically renews",
            "auto-renew-on": {
              removeClass: "hidden",
            },
            "auto-renew-off": {
              addClass: "hidden",
            }
          }, document);
        } else {
          Utility.updateContent({
            "auto-renew-copy": "ends",
            "auto-renew-off": {
              removeClass: "hidden",
            },
            "auto-renew-on": {
              addClass: "hidden",
            }
          }, document);
        }
      }
      if (save_offer.save_test_enabled && save_offer.display_offer) {
        this.form.successEl.firstElementChild.remove();
        let response_data = form.lastRequest.response?.data;
        let save_from = window.app_forms.collection.get("save-offer-form");
        save_from?.fill(response_data);
        let save_amount = response_data?.save_amount;
        let renewal_rate = response_data?.renewal_rate;
        if (renewal_rate != "2 years") {
          save_amount = save_amount + " per year";
        }
        Utility.updateContent({
          "save-offer-price": response_data?.price,
          "save-offer-amount": save_amount,
          "save-offer-renewal-rate": renewal_rate
        });
        window.app_dialogs?.activate("save-offer-dialog");
      }

      if (save_offer.save_free_trial && save_offer.display_free_trial_offer) {
        let response_data = form.lastRequest.response?.data;
        let save_trial_form = window.app_forms.collection.get("trial-save-offer-form");
        save_trial_form?.fill(response_data);
        Utility.updateContent({
          "trial-save-offer-price": response_data?.price,
          "trial-save-offer-discount": response_data?.discount,
          "trial-save-offer-renewal-rate": response_data?.renewal_rate,
          "trial-save-offer-current": response_data?.current_price
        });
        window.app_dialogs?.activate("trial-save-offer");
      }
    }
  }

  dateFieldsetInteraction() {

    const monthDays = {
      "January": 31,
      "February": 29,
      "March": 31,
      "April": 30,
      "May": 31,
      "June": 30,
      "July": 31,
      "August": 31,
      "September": 30,
      "October": 31,
      "November": 30,
      "December": 31,
    }

    const assignInteraction = (monthField, dayField, yearField = false) => {
      if (!dayField || !monthField) return
      const monthSelect = (label) => {
        if (label == "") {
          dayField.setValue("", true);
          dayField.el.disabled = true;
          dayField.el.removeAttribute("required");
          return
        }
        if (!(label in monthDays)) return
        dayField.el.disabled = false;
        dayField.el.setAttribute("data-ready", `range:1-${monthDays[label]}`);
        dayField.el.setAttribute("required", "");
        // Here setTimeout is used to escape initial loop and trigger after the field observer will trigger
        setTimeout(() => {
          if (dayField.el.value != "") {
            dayField.validate();
          }
        }, 0)
      }

      const yearSelect = (value) => {
        let valid = true;
        let first = true;
        yearField.rules.forEach(rule => {
          if (!rule.test(yearField.el.value) && yearField.enabled) {
            if (first) {
              valid = false;
              first = false;
            }
          }
        })

        if (valid) {
          if (value.trim().length == 0) {
            monthDays["February"] = 29;
          } else {
            if (Utility.isLeapYear(Number(value.trim()))) {
              monthDays["February"] = 29;
            } else {
              monthDays["February"] = 28
            }
          }
        } else {
          monthDays["February"] = 29
        }
        monthSelect(monthField.el.value);
      }

      if (dayField.el.value == "") {
        dayField.el.disabled = true;
      }
      if (monthField.el.value != "") {
        monthSelect(monthField.el.value);
      }
      if (yearField && yearField.el.value != "") {
        yearSelect(yearField.el.value);
      }
      monthField.onSelect = monthSelect;
      if (yearField) {
        yearField.onInput = yearSelect;
      }
    }

    // Collecting month/day fields from the form based on their fieldset aria-label or in common if not cointained inside a fieldset
    let fieldsets = new Map();
    this.form.fields.forEach(field => {
      ["month", "day", "year"].forEach(key => {
        const name = field.truename.replace("birthday", "");
        if (name.includes(key) && (field.type == "select" || field.type == "text")) {
          if (field.fieldset) {
            if (!fieldsets.has(field.fieldset)) {
              fieldsets.set(field.fieldset, new Map());
            }
            fieldsets.get(field.fieldset).set(key, field);
          } else {
            if (!fieldsets.has("common")) {
              fieldsets.set("common", new Map());
            }
            fieldsets.get("common").set(key, field);
          }
        }
      })
    })
    if (fieldsets.size != 0) {
      fieldsets.forEach(map => {
        if (!map.has("month") || !map.has("day")) return
        assignInteraction(map.get("month"), map.get("day"), map.get("year"))
      })
    }
  }

  signinInteraction() {
    if (!this.form.fields.has("forgot-password")) return

    const dialog = this.form.el.closest(".dialog");

    const closeForgotPassword = () => {
      const forgotPassword = this.form.fields.get("forgot-password");
      forgotPassword.setValue(false, true);
    }
    if (dialog && window.app_dialogs?.collection.has(dialog.id)) {
      window.app_dialogs.collection.get(dialog.id).onClose = closeForgotPassword;
    }

    const conditionalField = this.form.conditionalFieldsets.get("first");
    const originalEndpoint = this.form.el.getAttribute("data-endpoint");
    const originalRefreshes = this.form.el.hasAttribute("data-refresh");
    const originalTimer = this.form.successTimer;
    if (!conditionalField) return

    conditionalField.el.addEventListener("fieldset-change", (e) => {
      let fieldset = e.detail;
      this.form.removeErrors();
      this.form.notSubmitted = true;
      const isForgotPassword = fieldset.el.getAttribute("data-name") == "forgot-password";
      const dialogLabel = isForgotPassword ? "Reset Password" : "Log in to American Greetings";
      if (this.form.dialog) {
        this.form.dialog.el.setAttribute("aria-label", dialogLabel);
      } else {
        window.app_accessibility?.announce({
          context: this.form.el,
          message: `switched to ${isForgotPassword ? "forgot password" : "sign in"} panel`,
          mode: "polite",
          delay: 350,
          removeAfter: 4000,
        });
      }
      if (isForgotPassword) {
        this.form.actionLabel = "Reset Password";
        this.form.successTemplate = this.form.el.querySelector(".alt-success-message-template") || null;
        this.form.el.setAttribute("data-endpoint", `${window.app_env.shost}/myaccount/forgotpassword`);
        this.form.successTimer = 6000;
        this.form.nonBlocking = true;
        this.form.el.removeAttribute("data-refresh");
      } else {
        // This is to avoid autocomplete fill of input
        let passwordField = this.form.fields.get("password");
        let value = passwordField.getValue();
        value && passwordField?.setValue(value + " ");
        value && passwordField?.setValue(value);
        this.form.actionLabel = "Sign In";
        this.form.successTemplate = this.form.el.querySelector(".success-message-template") || null;
        this.form.successTimer = originalTimer;
        if (this.form.el.id == "sign-in") {
          this.form.el.setAttribute("data-endpoint", `${window.app_env.shost}/signin`);
        } else {
          this.form.el.setAttribute("data-endpoint", originalEndpoint);
        }
        if (originalRefreshes) {
          this.form.el.setAttribute("data-refresh", "");
        }
        this.form.nonBlocking = this.form.el.hasAttribute("data-non-blocking");
      }
      setTimeout(() => {
        this.form.fields.get("email")?.el.focus();
      }, 300)
    })
  }

  expiryDateInteraction() {
    if (this.form.fields.has("_cc_exp_year") && this.form.fields.has("_cc_exp_month")) {
      this.form.fields.forEach((instance, name) => {
        if (["_cc_exp_year", "_cc_exp_month"].includes(name)) {
          instance.onSelect = () => {
            this.form.fields.get("_cc_exp_year").removeError("server");
            this.form.fields.get("_cc_exp_year").rules.delete("server");
          }
        }
      })
    }
  }

  generateMessageInteraction() {
    if (!this.form.el.classList.contains("generate-message")) return
    let targetForm, targetField, lastMessage;
    let message_tries = Number(this.form.el.querySelector(`input[name="message_tries"]`)?.value) || 0;
    let message_state = this.form.el.querySelector(`input[name="message_state"]`)?.value || "Default";
    let generated_content = this.form.el.querySelector(`input[name="generated_content"]`)?.value || "";
    let options = null;
    let generate_message = this.form.el.querySelector(`input[name="generate_message"]`)?.value || "";
    let message_length = this.form.el.querySelector(`input[name="message_length"]`)?.value || 0;

    setTimeout(() => {
      targetForm = window.app_forms?.collection.get("product-detail-form") || window.app_forms?.collection.get("personalize");
      targetField = targetForm?.fields.get("message") || targetForm?.fields.get("pn1465");
      lastMessage = targetField.getValue();
      if (lastMessage) {
        message_length = targetField.getCharacterCount();
        this.form.fill({ message_length }, false);
      }

      targetField.onChange = () => {
        if (targetField.field.classList.contains("--inactive")) return
        message_length = targetField.getCharacterCount();
        if (lastMessage != targetField.getValue()) {
          message_state = message_state == "Default" ? "Own" : "Edited"
        }
        if (generated_content && generated_content == targetField.getValue()) {
          message_state = "Generated"
        }
        if (!generated_content && lastMessage == targetField.getValue()) {
          message_state = "Default"
        }
        this.form.fill({ message_length, message_state });
      }
    }, 300);

    this.form.filterFormDataGetter = (formData) => {
      if (!targetField) return
      lastMessage = targetField.getValue();
      targetField.field.classList.add("--inactive");
      targetField.disable();
      targetField.setValue("Generating Message...", true);
      targetField.el.disabled = true;
      let filteredFormData = new FormData();
      let generate_fields = {};
      let tone = "Funny";
      for (const [key, value] of formData.entries()) {
        if (!["favorite_activity", "occasion", "age", "tone"].includes(key)) continue
        if (key == "tone") {
          tone = value
        } else {
          generate_fields[key] = value || "";
        }
      }

      options = { ...generate_fields, tone };

      let extraParams = {
        "generate_fields": JSON.stringify(generate_fields),
        "tone": tone,
        "char_limit": window.app_env?.page_type == "pdp" ? 300 : 500,
        "client_id": window.app_env?.client_id || "",
      }

      for (let key in extraParams) {
        filteredFormData.append(key, extraParams[key])
      }

      return filteredFormData
    }

    this.form.onComplete = () => {
      if (!targetField) return
      if (this.form.status == "success") {
        generated_content = this.form.lastRequest.response?.data?.message || "";
        message_tries++;
        targetField?.setValue(generated_content, true);
        message_length = targetField.getCharacterCount();
        if (options) {
          generate_message = JSON.stringify({
            ...options, ...{
              number_of_generations: message_tries,
              message_length: message_length
            }
          });
        }
        message_state = "Generated";
        setTimeout(() => {
          targetField.setSelect();
        })
        this.form.fill({ message_state, generate_message, message_tries, generated_content, message_length });
        if (window.app_env?.has_generative_message_limit) {
          let message_count = this.incrementGenerativeMessageCount();
          if (message_count > 1) {
            // lock the ai messaging
            let form_holder = this.form.el.querySelector(
              ".layout.--small.--columns-2"
            );
            let disabled_dialog = this.form.el.querySelector(".form-disabled");
            disabled_dialog.style.display = "";
            form_holder.setAttribute("aria-hidden", true);
          }
        }
      } else {
        targetField?.setValue(lastMessage || "", true);
      }
      targetField.field.classList.remove("--inactive");
      targetField.enable();
      targetField.el.disabled = false;
    }
  }

  incrementGenerativeMessageCount() {
    let key_prefix = (
      window.app_env?.customer.is_in_trial ?
      window.app_env?.customer.number : "afu_rfu"
    );
    let key = `${key_prefix}_total_free_ai_messages_created`;
    let message_count = MagicCookie.getCookieValue(key);
    message_count = message_count ? parseInt(message_count) + 1 : 1;
    MagicCookie.setCookieValue(key, message_count, true);

    return message_count;
  }

  addRecaptcha() {
    if (window.app_env?.recaptcha) {
      if (this.form.el.hasAttribute("data-no-recaptcha-protect")) return
      let hasPassword = false;
      this.form.fields.forEach(field => {
        if (field.type.includes("password")) {
          hasPassword = true;
        }
      })
      if (hasPassword) {
        this.form.el.setAttribute("data-recaptcha-protect", "");
      }
    }
  }

  pofUpdateInteraction() {
    if (this.form.id !== "pof-update") return
    this.form.onBeforeSubmit = (form) => {
      if (form.el.querySelector('input[name="paymethod"]:checked').value == "paypal") {
        form.el.classList.add("--no-success-output");
      } else {
        form.el.classList.remove("--no-success-output");
      }
    }
  }

  processMembershipStarted() {
    if (!this.form.el.hasAttribute("data-membership-started-listener")) return
    document.addEventListener("app-customer-membership-started", e => {
      let source = e.detail.source;
      let current = this.form.el.querySelector(`#${source}`);
      if (this.form.id == "email-send") {
        let field = this.form.fields.get("delivery_date");
        let cash_gift_field = this.form.fields.get("cash_gift");
        if (cash_gift_field?.el.checked) {
          field?.field.querySelector(
            ".--primary-lock-reason"
          )?.classList.remove("--primary-lock-reason");
          field?.field.querySelector(
            "#cash-gift-instructions"
          )?.classList.add("--primary-lock-reason");
        } else {
          field?.lock(false);
        }
        if (current) {
          setTimeout(() => {
            window.app_page.wasKeyboardEvent && field.setFocus();
          }, 100)
        } else {
          field.field.querySelector("#delivery-date-unlock")?.remove()
        }
      } else if (this.form.id == "ai-message") {
        let formHolder = this.form.el.querySelector(".layout.--small.--columns-2");
        let banner = this.form.el.querySelector(".form-disabled");
        if (current) {
          setTimeout(() => {
            banner.querySelector(".dialog__overlay-btn")?.remove();
          }, 300);
          setTimeout(() => {
            formHolder?.setAttribute("aria-hidden", false);
            banner?.remove();
          }, 3000);
          if (window.app_page.wasKeyboardEvent) {
            setTimeout(() => {
              window.app_accessibility?.focus(formHolder);
            }, 3100);
          }
        } else {
          formHolder?.setAttribute("aria-hidden", false);
          banner?.remove();
        }
        if (window.app_env?.has_generative_message_limit) {
          window.app_env.has_generative_message_limit = false;
        }
      }
    })
  }

}

export default FormInteractions;
