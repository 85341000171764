import Client from "../base/Client";

class ApplePayment {

  constructor(form = null, flow = null, env = null) {
    this.supportedNetworks = ['masterCard', 'visa', 'discover', 'amex'];
    this.merchantCapabilities = ['supports3DS'];
    this.currencyCode = 'USD';
    this.countryCode = 'US';
    this.countries = null;
    this.form = form;
    this.payee = 'AmericanGreetings.com';
    this.taxAmount = 0.0;
    this.totalAmountPlusTaxes = 0.0;
    this.GSTTaxAmount = 0.0;
    this.HSTTaxAmount = 0.0;
    this.QSTTaxAmount = 0.0;
    this.PSTTaxAmount = 0.0;
    this.canadaTaxes = false;
    this.env = env || window.app_env;
    this.flow_type = flow || this.env?.payment_flow_type || "join";
    this.applePayOffer = String(this.env?.apple_pay_offer);
    this.isRFU = this.env.customer && ["REG", "RFU"].includes(this.env.customer.status);
    this.session = null;
    // Hooks
    this.onError = (errors) => { };
    this.onCancel = () => { };
  }

  /*
   * A request for payment, which includes information about payment
   * processing capabilities, the payment amount, and shipping information.
   */
  createApplePaymentRequest(labelText, purchaseAmount) {
    let individualLineItems = [];
    let requiredBillingContactFields = [];
    let requiredShippingContactFields = [];
    let totalAmount = parseFloat(purchaseAmount);
    individualLineItems = [this.createLineItem(this.getDefaultSubscriptionName(), purchaseAmount)];
    if (this.flow_type == 'detail') {
      if (!this.isRFU) {
        requiredShippingContactFields = ['name', 'email'];
      }
      if (this.applePayOffer != '0') {
        // apply the discount only for detail and join flow
        // we need to pass the discount amount to the apple pay session
        let percent = this.getPercent(purchaseAmount, this.applePayOffer).toFixed(0);
        let discountAmount = (purchaseAmount - this.applePayOffer).toFixed(2);
        individualLineItems = [this.createLineItem(this.getDefaultSubscriptionName(), purchaseAmount),
        this.createCouponLineItem('INTRO DISCOUNT', percent, discountAmount)
        ];
        totalAmount = this.applePayOffer;
      }
    }
    if (this.flow_type == "join") {
      isYearlySubChecked = !$('input[name=pricechoice]:checked').is(':checked');
      this.applePayOffer = '0'
      if (this.applePayOffer != '0' && isYearlySubChecked) {
        // apply the discount only for detail and join flow
        // we need to pass the discount amount to the apple pay session
        let percent = this.getPercent(purchaseAmount, this.applePayOffer).toFixed(0);
        let discountAmount = (purchaseAmount - this.applePayOffer).toFixed(2);
        individualLineItems = [this.createLineItem(this.getDefaultSubscriptionName(), purchaseAmount),
        this.createCouponLineItem('INTRO DISCOUNT', percent, discountAmount)
        ];
        totalAmount = this.applePayOffer;
      }
    }
    if (this.flow_type == 'detail' || this.flow_type == "join") {
      requiredShippingContactFields.push('postalAddress');
    }
    return {
      currencyCode: this.currencyCode,
      countryCode: this.countryCode,
      requiredShippingContactFields: requiredShippingContactFields,
      total: this.createLineItem(labelText, totalAmount),
      lineItems: individualLineItems,
      supportedNetworks: this.supportedNetworks,
      merchantCapabilities: this.merchantCapabilities
    };
  }

  /*
   * Updates the payment request
   */
  updateApplePaymentRequest(labelText, purchaseAmount, applyTaxes, canadaTaxes) {
    let newIndividualLineItems = [];
    let totalAmount = parseFloat(purchaseAmount);

    if (applyTaxes) {
      totalAmount = this.totalAmountPlusTaxes;
      newIndividualLineItems = [this.createLineItem(this.getDefaultSubscriptionName(), purchaseAmount)];
      if (canadaTaxes) {
        if (this.GSTTaxAmount) {
          newIndividualLineItems.push(this.createTaxLineItem('GST', this.GSTTaxAmount));
        }
        if (this.HSTTaxAmount) {
          newIndividualLineItems.push(this.createTaxLineItem('HST', this.HSTTaxAmount));
        }
        if (this.QSTTaxAmount) {
          newIndividualLineItems.push(this.createTaxLineItem('QST', this.QSTTaxAmount));
        }
        if (this.PSTTaxAmount) {
          newIndividualLineItems.push(this.createTaxLineItem('PST', this.PSTTaxAmount));
        }
      } else {
        if (this.taxAmount) {
          newIndividualLineItems.push(this.createTaxLineItem('Tax', this.taxAmount));
        }
      }
    } else {
      newIndividualLineItems = [this.createLineItem(this.getDefaultSubscriptionName(), purchaseAmount)];
    }

    return {
      newTotal: this.createLineItem(labelText, totalAmount),
      newLineItems: newIndividualLineItems,
    };
  }

  createLineItem(label, amount) {
    return {
      label: label,
      amount: amount
    };
  }

  createCouponLineItem(couponCode, percent, amount) {
    return {
      label: couponCode + ": " + percent + "% OFF",
      amount: amount
    };
  }

  createTaxLineItem(label, amount) {
    return {
      label: label,
      amount: amount
    }
  }

  getPercent(amount, discountedAmount) {
    return 100 * (amount - discountedAmount) / amount;
  }

  getDefaultSubscriptionName() {
    /*return for join flow the subscription name selected*/
    if (this.flow_type == "join") {
      if (this.env.customer.is_in_collections) {
        return this.env.collection_subscription_name + ' Membership';
      } else {
        const subscriptionName = $('input[name=pricechoice]:checked').parent().find('.membership-option__title').text().trim().split("$")[0].trim();
        return subscriptionName.capitalize() + ' Membership';
      }
    } else if (this.flow_type == "pof") {
      return "Update Payment on File"
    } else if (this.flow_type == "digital_gift") {
      return "Order total"
    } else {
      return this.env.apple_pay_offer_name;
    }
  }

  getSubscriptionName() {
    return this.payee;
  }

  getSubscriptionPrice() {
    /*return for join flow the subscription price selected*/
    if (this.flow_type == "join") {
      if (this.env.customer.is_in_collections) {
        collectionSubscriptionPrice = parseFloat(this.env.collection_subscription_price);
        if (!isNaN(collectionSubscriptionPrice)) {
          return collectionSubscriptionPrice;
        } else {
          const registerForm = app_forms.collection.get('register-form');
          let message = "Server error occured, please try again later.";
          if (registerForm) {
            registerForm.addError("server", message);
            registerForm.complete("error-server");
          }
          return null;
        }
      } else {
        return $('input[name=pricechoice]:checked').parent().find(".membership-option__price").text().split("$")[1].replace(" ", "");
      }
    } else if (this.flow_type == "pof") {
      // we need to authorize the user's card in order to update the pof method, so we do a charge of $0.01
      return "0.01"
    } else if (this.flow_type == "digital_gift") {
      // TODO: Identify the element related to the digital gift for AG base on id/class
      return $('.payment-digital-gift-price').html().split("$")[1]
    } else {
      // Price for Apple Pay default membership
      return this.env.apple_pay_offer;
    }
  }

  /*
   * this function signs the customer in after we create an account for him
   */
  ajaxSignInCustomer(email) {
    const self = this;
    jQuery.ajax({
      dataType: 'json',
      type: 'POST',
      headers: {
        'X-Csrftoken': Client.getCSRFToken(),
      },
      url: this.env.shost + '/applepay-signin',
      data: {
        'email': email,
      },
      success() {
        // If customer is RFU dont show password field and reload the page
        if (self.isRFU) {
          window.location.reload();
        } else {
          // Refresh the page and show the password field input to the customer
          let url = new URL(window.location.href);
          url.searchParams.append("set_password", 1);
          window.history.replaceState({}, 'unused', url);
          window.location.reload();
        }
      },
      error(json) {
        self.handleErrors(JSON.parse(json.responseText)?.data?.message);
      }
    });
  }

  /*
   * this function creates an account for the customer with all the relevant
   * information. this applies only for the detail flow
   * TODO: Refactor this function to implement AG logic for Product Detail Page
   */
  ajaxCreateUserProfile(
    email,
    first_name,
    last_name,
    country,
    city,
    street_address_1,
    street_address_2,
    state,
    postal_code,
    token,
    recaptcha_token,
  ) {
    const self = this;
    jQuery.ajax({
      dataType: 'json',
      type: 'POST',
      url: 'https://' + location.host + '/join/applepay/payment',
      data: {
        'email': email,
        'first_name': first_name,
        'last_name': last_name,
        'country': country,
        'city': city,
        'street_address_1': street_address_1,
        'street_address_2': street_address_2,
        'state': state,
        'zip_code': postal_code,
        'digital_token_cryptogram': token,
        'is_rfu': self.isRFU,
        'tracking_id': this.env.tracking_id,
        'r_token': recaptcha_token,
      },
      success(data, response, xhr) {
        // Complete Apple Pay payment session
        self.session.completePayment({
          status: ApplePaySession.STATUS_SUCCESS
        });
        if (!self.isRFU) {
          self.ajaxSignInCustomer(email);
        } else {
          window.location.reload();
        }
      },
      error(json) {
        self.session.abort();
        let generic_error = "Server error occured, please try again later.";
        let errorMessage;
        try {
            const parsedResponse = JSON.parse(json?.responseText);
            errorMessage = parsedResponse?.data?.message || generic_error;
        } catch (e) {
            errorMessage = "Server error occured, check your ApplePay billing information and please try again.";
        }
        self.handleErrors(errorMessage);
      }
    });
  }

  /*
   * this functions retrieves the taxes we need to display to the user, based
   * on zip code and state
   */
  getTaxes(country, state, zipCode, applyTaxes, salestaxCart) {
    const self = this;
    jQuery.ajax({
      dataType: 'json',
      type: 'POST',
      url: 'https://' + location.host + '/get-taxes',
      data: {
        'salestax_cart': salestaxCart,
        'country': country,
        'sitegroup': this.env.sitegroup,
        'state': state,
        'zip_code': zipCode,
      },
      success(data) {
        if (data) {
          self.taxAmount = parseFloat(data.total_tax);
          self.totalAmountPlusTaxes = parseFloat(data.total_amount);
          if (country == 'CANADA') {
            self.GSTTaxAmount = parseFloat(data.GST);
            self.HSTTaxAmount = parseFloat(data.HST);
            self.QSTTaxAmount = parseFloat(data.QST);
            self.PSTTaxAmount = parseFloat(data.PST);
          }
        } else {
          self.taxAmount = 0.0;
          self.totalAmountPlusTaxes = self.getSubscriptionPrice();
        }
        self.session.completeShippingContactSelection(
          self.updateApplePaymentRequest(
            self.getSubscriptionName(),
            self.getSubscriptionPrice(),
            applyTaxes,
            self.canadaTaxes));
      },
      error(json) {
        self.taxAmount = 0.0;
        self.totalAmountPlusTaxes = self.getSubscriptionPrice();
        self.session.completeShippingContactSelection(
          self.updateApplePaymentRequest(
            self.getSubscriptionName(),
            self.getSubscriptionPrice(),
            applyTaxes,
            self.canadaTaxes));
        self.cancelHandler();
      }
    });
  }

  /*
   * this function validates the zipcode only for US/Canada/UK and returns
   * the error message in case it is invalid
   */
  async validateZipCode(country, zipCode, state) {
    var isValid = true;
    var message = '';
    if (this.flow_type == 'detail' || this.flow_type == "join") {
      let isZipStateValid = false;
      if (['USA', 'CANADA'].includes(country)) {
        isZipStateValid = await this.validateZipState(country, zipCode, state);
      }
      if (country == 'USA') {
        var us_regex = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$');
        if (!zipCode.match(us_regex) || !isZipStateValid) {
          message = 'Please enter a valid US zip code and state combination.'
          isValid = false;
        }
      } else if (country == 'CANADA') {
        var canada_regex = new RegExp('[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]');
        if (!zipCode.match(canada_regex) || !isZipStateValid) {
          message = 'Please enter a valid Canadian postal code and province combination.'
          isValid = false;
        }
      } else if (country == 'UK') {
        var uk_regex = new RegExp('[A-Za-z]{1,2}[0-9][0-9A-Za-z]{0,1} {0,1}[0-9][A-Za-z]{2}');
        if (!zipCode.match(uk_regex)) {
          message = 'Please enter a valid UK postal code.'
          isValid = false;
        }
      }
    }
    if (isValid) {
      return [zipCode, ''];
    }
    return ['', message];
  }

  /*
   * this function validates the state/province for the selected country and
   * returns an error message in case it is invalid
   */
  async validateScp(country, state) {
    if (!this.countries) {
      this.countries = await window.app_services?.getCountries();
      if (!this.countries) return ['', 'Could not load countries'];
    }
    var options = this.countries;
    for (var option in options) {
      if (option == country) {
        var scpHeading = options[option]['scp_heading'];
        for (var scp in options[option]['scp']) {
          if (country == 'UNITED KINGDOM') {
            try {
              if (options[option]['scp'][scp]['name'].toLowerCase() == state ||
                options[option]['scp'][scp]['name'].toLowerCase().indexOf(state) > -1) {
                return [options[option]['scp'][scp]['abbreviation'], ''];
              }
            } catch (err) {
              return ['', 'Please enter a valid ' + scpHeading.toLowerCase()];
            }

          } else if (options[option]['scp'][scp]['abbreviation'] == state) {
            return [state, ''];
          }
        }
      }
    }
    return ['', 'Please enter a valid ' + scpHeading.toLowerCase()];
  }

  /*
   * this function takes the country that the user selects from the Apple Pay
   * dropdown and converts it to the format we need on our end
   */
  async validateZipState(country, zip, state) {
    try {
      let response = await fetch(`/validate-address?country=${country}&zip_code=${zip}&state=${state}`);
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Failed to validate address:", error);
      return false;
    }
  }

  formatCountry(country) {
    if (country == 'United States') {
      return 'USA';
    } else if (country == 'United Kingdom') {
      return 'UNITED KINGDOM';
    } else {
      return country.toUpperCase();
    }
  }

  /*
   * this function makes a request POST request to the backend to validate
   * all the information and complete the customer subscription.
   */
  completePaymentSubscriptionOrder(formdata) {
    const self = this;
    jQuery.ajax({
      type: 'POST',
      url: 'https://' + location.host + '/join/payment',
      data: formdata,
      contentType: false,
      processData: false,
      success(response, status, xhr) {
        const contentType = xhr.getResponseHeader("content-type") || "";
        if (contentType && contentType.indexOf("application/json") !== -1) {
          if (response.hasOwnProperty('status') && response.hasOwnProperty('data')) {
            if (response.status == "success" && response.data.hasOwnProperty('redirect_url')) {
              self.session.completePayment({
                status: ApplePaySession.STATUS_SUCCESS
              });
              window.location = response.data.redirect_url
            }
          }
        }
      },
      error(response, status) {
        const registerForm = app_forms.collection.get('register-form');
        const genericErrorMessage = "Server error occured, please try again later.";
        let errorResponse = jQuery.parseJSON(response.responseText);
        if (errorResponse.hasOwnProperty("status") && errorResponse.status == "error") {
          self.session.completePayment({
            status: ApplePaySession.STATUS_FAILURE
          });
          registerForm.addError("server", errorResponse?.data?.message);
          registerForm.complete("error-server");
        } else {
          self.session.completePayment({
            status: ApplePaySession.STATUS_FAILURE
          });
          registerForm.addError("server", genericErrorMessage);
          registerForm.complete("error-server");
        }
      }
    });
  }



  /*
   * this function submits the Apple Pay data form which includes encrypted payment token
   * details
   */
  async submitApplePayDataForm() {
    if (!Client.supportsApplePay || !window.ApplePaySession) {
      this.cancelHandler();
      return;
    }
    let paymentRequest = this.createApplePaymentRequest(this.getSubscriptionName(), this.getSubscriptionPrice());
    this.session = new ApplePaySession(3, paymentRequest);
    this.session.begin();
    this.session.onvalidatemerchant = this.validateMerchantHandler.bind(this);
    this.session.onshippingcontactselected = this.shippingContactSelectedHandler.bind(this);
    this.session.onpaymentauthorized = this.paymentAuthorizedHandler.bind(this);
    this.session.oncancel = this.cancelHandler.bind(this);
  }

  getRecaptchaToken() {
    return new Promise((resolve, reject) => {
      if (!window.grecaptcha) return reject();
      window.grecaptcha.ready(async () => {
        try {
          let token = await window.grecaptcha.execute(this.env.recaptcha, { action: "applepayment" });
          resolve(token);
        } catch (error) {
          reject();
        }
      });
    })
  }

  // An event handler the system calls when it displays the payment sheet
  validateMerchantHandler(event) {
    const self = this;
    jQuery.ajax({
      dataType: 'json',
      type: 'POST',
      url: '/merchant-validation',
      data: {
        'apple_url': event.validationURL
      },
      success(json) {
        self.session.completeMerchantValidation(json);
      },
      error(json) {
        self.session.abort();
        console.error(JSON.parse(json.responseText)?.error)
        self.handleErrors("Server error occured, please try again later.");
      }
    });
  }

  // An event handler that is called when the user has authorized the
  // Apple Pay payment with Touch ID, Face ID, or passcode
  async paymentAuthorizedHandler(event) {
    if (this.env.payment_flow_type == 'pof') {
      // TODO: Implement Apple Pay POF update
      this.session.completePayment({
        status: ApplePaySession.STATUS_SUCCESS
      });
    } else if (this.env.payment_flow_type == "join") {
      // set token cryptogram here
      const registerFormInstance = app_forms.collection.get('register-form')
      registerFormInstance.extraRequestParams['digital_token_cryptogram'] = JSON.stringify(event.payment.token.paymentData)

      // Add recaptcha token to apple pay payment flow
      let recaptcha_token = "";
      try {
        recaptcha_token = await this.getRecaptchaToken()
      } catch (error) { }
      registerFormInstance.extraRequestParams['r_token'] = recaptcha_token

      let postalCode = '';
      let message = '';

      // First clear all the form values in case the user has some
      // other data filled in
      $('#register-form-country, \
        #register-form-zip_code, \
        #register-form-street_address_1, \
        #register-form-street_address_2, \
        #register-form-city, \
        #register-form-state').attr('value', '');

      let country = this.formatCountry(event.payment.shippingContact.country);
      let city = event.payment.shippingContact.locality || '';
      let streetAddress_1 = event.payment.shippingContact.addressLines[0] || '';
      let streetAddress_2 = event.payment.shippingContact.addressLines[1] || '';
      let state = event.payment.shippingContact.administrativeArea || '';
      postalCode = event.payment.shippingContact.postalCode || '';

      registerFormInstance.extraRequestParams['country'] = country;
      if (['USA', 'UNITED KINGDOM', 'CANADA'].indexOf(country) > -1) {
        // Save the shipping information only if the country is one
        // of USA, UK or Canada
        city = event.payment.shippingContact.locality;
        streetAddress_1 = event.payment.shippingContact.addressLines[0];
        streetAddress_2 = event.payment.shippingContact.addressLines[1];

        // validate the state/province so that the user does not enter
        // garbage data
        if (country == 'UNITED KINGDOM') {
          message = '';
          state = event.payment.shippingContact.administrativeArea.toLowerCase();
          if (state.split(' ').length > 1) {
            state = state.replace('west', 'w')
              .replace('east', 'e')
              .replace('north', 'n')
              .replace('south', 's');
          }
          [state, message] = await this.validateScp(country, state);
        } else {
          [state, message] = await this.validateScp(country,
            event.payment.shippingContact.administrativeArea);
        }
        if (message) {
          registerFormInstance.addError("server", message);
          registerFormInstance.complete("error-server");
          this.session.completePayment({
            status: ApplePaySession.STATUS_FAILURE
          });
          return;
        }

        // validate the zip code so that the user does not enter
        // garbage data
        [postalCode, message] = await this.validateZipCode(country,
          event.payment.shippingContact.postalCode,
          event.payment.shippingContact.administrativeArea);

        // if the user inserted a wrong zip code, stop the Apple Pay
        // session and return the error message
        if (message) {
          registerFormInstance.addError("server", message);
          registerFormInstance.complete("error-server");
          this.session.completePayment({
            status: ApplePaySession.STATUS_FAILURE
          });
          return;
        }

        // fill in the billing information fields so that we have
        // these values when we submit the form

        registerFormInstance.extraRequestParams['street_address_1'] = streetAddress_1;
        registerFormInstance.extraRequestParams['street_address_2'] = streetAddress_2 ? streetAddress_2 : "";
        registerFormInstance.extraRequestParams['city'] = city;
        registerFormInstance.extraRequestParams['state'] = state;

        if (country == 'UNITED KINGDOM' && !state) {
          // If we couldn't match the state that the user entered
          // in the Apple Pay sheet, just default to the first
          // state to make things work
          registerFormInstance.extraRequestParams['state'] = 'ABD';
        } else {
          registerFormInstance.extraRequestParams['state'] = state;
        }
        registerFormInstance.extraRequestParams['zip_code'] = postalCode;
      } else {
        // Other countries billing information should be provided
        registerFormInstance.extraRequestParams['street_address_1'] = streetAddress_1;
        registerFormInstance.extraRequestParams['street_address_2'] = streetAddress_2 ? streetAddress_2 : "";
        registerFormInstance.extraRequestParams['city'] = city;
        registerFormInstance.extraRequestParams['state'] = state;
        registerFormInstance.extraRequestParams['zip_code'] = postalCode;
      }

      this.completePaymentSubscriptionOrder(registerFormInstance.getFormData())
    } else if (this.flow_type == 'detail') {
      // send cryptogram to backend and create a profile for the user
      var email = '';
      var firstName = '';
      var lastName = '';
      var postalCode = '';
      var message = '';

      if (this.isRFU) {
        email = this.env?.customer?.email;
        firstName = '';
        lastName = '';
      } else {
        email = event.payment.shippingContact.emailAddress;
        firstName = event.payment.shippingContact.givenName;
        lastName = event.payment.shippingContact.familyName;
      }

      let country = this.formatCountry(event.payment.shippingContact.country);
      if (['USA', 'UNITED KINGDOM', 'CANADA'].indexOf(country) > -1) {
        // Save the shipping information only if the country is one
        // of USA, UK or Canada
        var city = event.payment.shippingContact.locality;
        var streetAddress_1 = event.payment.shippingContact.addressLines[0];
        var streetAddress_2 = event.payment.shippingContact.addressLines[1];

        // validate the state/province so that the user does not enter
        // garbage data
        if (country == 'UNITED KINGDOM') {
          message = '';
          var state = event.payment.shippingContact.administrativeArea.toLowerCase();
          if (state.split(' ').length > 1) {
            state = state.replace('west', 'w')
              .replace('east', 'e')
              .replace('north', 'n')
              .replace('south', 's');
          }
          [state, message] = await this.validateScp(country, state);
        } else {
          [state, message] = await this.validateScp(country,
            event.payment.shippingContact.administrativeArea);
        }
        if (message) {
          this.session.completePayment({
            status: ApplePaySession.STATUS_FAILURE
          });
          this.handleErrors(message);
          return;
        }

        // validate the zip code so that the user does not enter
        // garbage data
        [postalCode, message] = await this.validateZipCode(country,
          event.payment.shippingContact.postalCode,
          event.payment.shippingContact.administrativeArea);

        // if the user inserted a wrong zip code, stop the Apple Pay
        // session and return the error message
        if (message) {
          this.session.completePayment({
            status: ApplePaySession.STATUS_FAILURE
          });
          this.handleErrors(message);
          return;
        }
      } else {
        city = '';
        streetAddress_1 = '';
        streetAddress_2 = '';
        state = '';
        postalCode = '';
      }

      let recaptcha_token = "";
      try {
        recaptcha_token = await this.getRecaptchaToken()
      } catch (error) { }

      this.ajaxCreateUserProfile(email, firstName, lastName,
        country, city, streetAddress_1, streetAddress_2, state, postalCode,
        JSON.stringify(event.payment.token.paymentData), recaptcha_token);
    } else if (this.flow_type == 'digital_gift') {
      // TODO: Apple Pay implementation for digital gift
      $('#payment-gift-form').on('submit', (event) => {
        this.session.completePayment({
          status: ApplePaySession.STATUS_SUCCESS
        });
      });
      $('#payment-gift-form').submit();
    }
  }

  // An event handler that is called when a shipping contact is
  // selected in the payment sheet
  shippingContactSelectedHandler(event) {
    let applyTaxes = false;
    this.canadaTaxes = false;

    let country = this.formatCountry(event.shippingContact.country);
    let state = event.shippingContact.administrativeArea;
    let zipCode = event.shippingContact.postalCode;
    if (['USA', 'CANADA'].indexOf(country) > -1) {
      applyTaxes = true;
      if (country == 'CANADA') {
        this.canadaTaxes = true;
      }
    }

    if (applyTaxes) {
      if (country == 'CANADA') {
        // The event returned from Apple Pay contains only the
        // first part of the zip code, so we will need to retrieve
        // the taxes based on the state
        zipCode = ''
      }

      if (this.flow_type == "join") {
        var salestaxCartChoice = $('input[name=pricechoice]:checked').val();
      } else if (this.flow_type == 'detail') {
        var salestaxCartChoice = this.env?.product["product_number"];
      }

      let salestaxCart = {}
      salestaxCart[salestaxCartChoice] = {
        'item': this.getDefaultSubscriptionName(),
        'price': this.getSubscriptionPrice(),
        'quantity': 1
      }

      this.getTaxes(country, state, zipCode, applyTaxes, JSON.stringify(salestaxCart));
    } else {
      this.session.completeShippingContactSelection(
        this.updateApplePaymentRequest(
          this.getSubscriptionName(),
          this.getSubscriptionPrice(),
          applyTaxes,
          this.canadaTaxes));
    }
  }

  cancelHandler(event) {
    this.onCancel();
  }

  handleErrors(errors) {
    this.cancelHandler();
    this.onError(errors);
  }
}

export default ApplePayment