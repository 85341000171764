import Heartbeat from "@common/modules/utilities/Heartbeat";
import Client from "../../../base/Client";
import Render from "../../../base/Render";
import Utility from "../../../base/Utility";

class GiftCardProvider {
  constructor(config, manager) {
    this.manager = manager;
    this.config = config;
    this.giftOption = config.keyword;
    this.label = config.description;
    this.sendFlow = manager.sendFlow;
    this.init();
  }

  init() {
    this.manager.initGiftProvider(this.config);
    this.initGiftPanel();
  }

  addGiftAttached() {
    this.manager.addGiftAttached(this.giftOption);
  }

  initGiftPanel() {
    let form = this.sendFlow.forms.get("select-gift-form");
    let panel = this.sendFlow.wizard.panels.get("select-gift");
    let iframeHolder = panel?.el.querySelector("#jifiti-merchant");
    if (!panel || !form || !iframeHolder) return

    let heartbeat = null;
    if (this.sendFlow.order.mode == 'send' || this.sendFlow.order.mode == 'edit') {
      this.renderIframeEcardFirst(form, panel, iframeHolder, heartbeat);
    } else {
      this.renderIframeGiftCardFirst(form, panel, iframeHolder, heartbeat);
    }
  }

  renderIframeEcardFirst(form, panel, iframeHolder, heartbeat) {
    // Render iframe before panel activation
    panel.el.addEventListener("panel-before-activation", async () => {
      let loader = iframeHolder.querySelector(".lazy-load-iframe__loader");
      await Utility.resourcesLoaded(
        "jifiti",
        [this.sendFlow.order.script_src],
        false,
        () => (window.GiftitSDK)
      );
      setTimeout(() => {
        iframeHolder.classList.add("--loaded");
        setTimeout(() => {
          loader?.remove();
        }, 300)
      }, 500);
      let fullName;
      if (app_env.customer) {
        let firstName = app_env.customer.name;
        let lastName = app_env.customer.lname;
        fullName = firstName.concat(" ", lastName);
      }
      const dateObject = new Date(this.sendFlow.order.send_date);
      const giftitSDK = new GiftitSDK({
        element: {
          selector: '.gift-container__iframe',
          width: "100%",
          height: "660px",
        },
        partnerId: this.sendFlow.order.partner_id,
        token: this.sendFlow.order.callback_token,
        profileId: this.sendFlow.order.profile_id,
        ecard_id: this.sendFlow.order.id,
        gifter: {
          name: fullName,
          email: app_env.customer.email
        },
        recipients: this.sendFlow.order.recipients,
        currencyCode: this.sendFlow.order.currency_code,
        sendDate: dateObject,
        onFinish(qrCodes) {
          panel.disableNavigation();
          heartbeat?.kill();
          heartbeat = new Heartbeat(30, 15);
          heartbeat.onSuccess = (data) => {
            form.el.submit();
          }
          heartbeat.onFail = (reason) => {
            form.addError("server", "Unable to connect to merchant, please try again later.");
            form.renderErrors();
            panel.enableNavigation();
          }
          heartbeat.start(`${app_env.shost}/gifts/transaction-started/${app_env.ecard_order.id}${app_env.ecard_order.sender_id_fragment}`, {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-Csrftoken': Client.getCSRFToken(),
            }
          });
        }
      })
    });
    // Clear iframe and errors after deactivation
    panel.el.addEventListener("panel-deactivated", () => {
      form.clearErrors(["server"]);
      heartbeat?.kill();
      iframeHolder.classList.remove("--loaded");
    });
  }

  async renderIframeGiftCardFirst(form, panel, iframeHolder, heartbeat) {
    let loader = iframeHolder.querySelector(".lazy-load-iframe__loader");
    const searchParams = new URL(location.href).searchParams;
    await Utility.resourcesLoaded(
      "jifiti",
      [this.sendFlow.order.script_src],
      false,
      () => (window.GiftitSDK)
    );
    setTimeout(() => {
      iframeHolder.classList.add("--loaded");
      setTimeout(() => {
        loader.remove();
      }, 300)
    }, 500);
    let fullName;
    if (app_env.customer) {
      let firstName = app_env.customer.name;
      let lastName = app_env.customer.lname;
      fullName = firstName.concat(" ", lastName);
    }

    const giftitSDK = new GiftitSDK({
      element: {
        selector: '.gift-container__iframe',
        width: "100%",
        height: "725px",
      },
      partnerId: this.sendFlow.order.partner_id,
      token: this.sendFlow.order.callback_token,
      profileId: this.sendFlow.order.profile_id,
      ecard_id: this.sendFlow.order.id,
      gifter: {
        name: fullName,
        email: app_env.customer.email
      },
      sendDate: new Date(), // Today or some other date.
      store: searchParams.get("store"),
      onFinish(qrCodes) {
        panel.disableNavigation();
        heartbeat?.kill();
        heartbeat = new Heartbeat(30, 15);
        heartbeat.onSuccess = (data) => {
          form.el.submit();
        }
        heartbeat.onFail = (reason) => {
          form.addError("server", "Unable to connect to merchant, please try again later.");
          form.renderErrors();
          panel.enableNavigation();
        }
        heartbeat.start(`${app_env.shost}/gifts/transaction-started/${app_env.ecard_order.id}${app_env.ecard_order.sender_id_fragment}`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Csrftoken': Client.getCSRFToken(),
          }
        });
      }
    })
  }
}

export default GiftCardProvider;