import Utility from "../base/Utility";

class Spinner {
  constructor(config = {}) {
    this.config = {
      template: "spinner",
      ...config
    };
    this.listeners = new AbortController();
    this.destroyed = false;
    this.spinnerStatus = this.config.status || {};
    this.processingInterval = null;
    this.dialog = window.app_dialogs?.spawn(
      this.config.id || "general-spinner",
      this.config,
      this.config.open ?? true,
      this.config.trigger || null,
    );
    setTimeout(() => {
      this.init();
    }, 350);
    // Hooks
    this.onAbort = () => {};
  }

  init() {
    if (this.destroyed) return
    window.app_listeners?.add('pageshow', 'spinner-resets', (e) => {
      e.persisted && this.close()
    });
    this.processingInterval && clearInterval(this.processingInterval);
    let time = 0;
    this.processingInterval = setInterval(() => {
      if (time >= 70) {
        clearInterval(this.processingInterval);
        return
      };
      time += Utility.getRandomInt(5, 15);
      this.update({ time });
    }, Utility.getRandomInt(100, 1000));
    this.dialog.el.addEventListener("dialog-closed", () => {
      this.abort();
    })
  }

  abort(content = null) {
    this.onAbort();
    clearInterval(this.processingInterval);
    this.destroyed = true;
    window.app_listeners?.remove('pageshow', 'spinner-resets')
    content && this.update(content);
  }

  close() {
    window.app_dialogs.close(this.dialog.name);
  }

  update(content = {}) {
    this.spinnerStatus = { ...this.spinnerStatus, ...content };
    let state = content.state || this.spinnerStatus.state || "loading";
    let time = content.time || this.spinnerStatus.time || 0;
    let progress = content.progress || this.spinnerStatus.progress || 0;
    if (state == "error") {
      clearInterval(this.processingInterval);
    }
    let value = (time + progress) / 2;
    Utility.updateContent({
      "spinner": {
        removeClass: ["--loading", "--completed", "--error"].removeItem(`--${state}`),
        addClass: `--${state}`,
        style: `--loader-progress:${value}%;`
      },
      "spinner__title": content.title || this.spinnerStatus.title || "Processing...",
      "spinner__progress-label": `${Math.round(value)}%`,
      ...content
    }, this.dialog.el);
  }

  completed(content = {}) {
    clearInterval(this.processingInterval);
    this.update({
      title: "Completed",
      state: "completed",
      time: 100,
      progress: 100,
      ...content
    })
  }
}

export default Spinner