import Utility from "../../../base/Utility";
import Render from "../../../base/Render";
import Spinner from "../../Spinner";

class CashGiftProvider {
  constructor(config, manager) {
    this.manager = manager;
    this.config = config;
    this.giftOption = config.keyword;
    this.label = config.description;
    this.sendFlow = manager.sendFlow;
    this.cashGiftSettings = window.app_env?.cash_gift_config;
    this.cashGiftModule = null;
    this.cashGiftSpinner = null;
    this.cashGiftCompleted = false;
    this.cashGiftTotal = 0;
    this.cashGiftFailures = [];
    this.hasCashGiftSelected = this.sendFlow.order.personalizations.has_cash_gift_selected || false;
    this.init();
  }

  init() {
    this.manager.initGiftProvider(this.config);
    this.initCashGiftPanel();
    if (this.hasCashGiftSelected) {
      this.initCashGiftPanelCacShare();
    }
  }

  addGiftAttached() {
    this.manager.addGiftAttached(this.giftOption);
    Utility.updateContent({
      "send-email-description": "Select or create recipient(s) for your greeting and customize your name.",
      "cash-gift-instructions": {
        removeClass: "hidden",
        addClass: "--primary-lock-reason"
      },
      "hide-on-lock": {
        addClass: "hidden",
      },
      "delivery-date-label": {
        addClass: "--has-icon"
      },
      "delivery-date-inputs-fieldset": {
        "disabled": "",
      },
    }, this.sendFlow.el);
    let datePicker = this.sendFlow.forms.get("email-send").fields.get("delivery_date");
    if (datePicker && datePicker.isInit && !datePicker.locked) {
      datePicker.el.setAttribute("data-locked", "");
      datePicker.lock(true);
      let today = new Date();
      datePicker.setValue(today.toJSON(), false);
    }
    this.sendFlow.checkFutureDate(datePicker?.getLocalValue());
  }

  initCashGiftPanelCacShare() {
    this.sendFlow.order.delivery_method = "copy";
    this.sendFlow.deliveryDetails.select_cash_gift = true;
    Utility.updateContent({
      "share-phone-number-container": {
        removeClass: "hidden",
      },
      "cash-gift-container": {
        addClass: "hidden",
      },
      "cash-amounts-container": {
        addClass: "hidden",
      },
      "cash-amount-container": {
        removeClass: "hidden",
      },
    }, this.sendFlow.el);
    this.sendFlow.wizard.panels.get("select-cash-gift")?.enable();
  }

  initCashGiftPanel() {
    let form = this.sendFlow.forms.get("select-cash-gift-form");
    let panel = this.sendFlow.wizard.panels.get("select-cash-gift");
    let container = panel?.el.querySelector("#cash-gift-container");
    if (!panel || !form || !container) return
    let cash_amount_element = panel.el.querySelector(
      "#cash-amount-form #cash-amount"
    );
    cash_amount_element.addEventListener(
      "input", this.handleCashAmountChange.bind(this)
    );
    panel.el.querySelectorAll("#cash-amount-form .cash-amount-option").forEach(
      (element) => {
        element.addEventListener(
          "click", this.handleCashAmountOptionChange.bind(this)
        );
      }
    );
    let cash_gift_phone_element = panel.el.querySelector(
      "#cash-amount-form #cash-gift-recipient-phone-number"
    );
    cash_gift_phone_element.addEventListener(
      "keypress", this.handleCashGiftRecipPhoneKeyPress.bind(this)
    );
  }

  handleCashAmountKeyPress(event) {
    let key_code = event.which ? event.which : event.keyCode;
    if (key_code > 31 && (key_code < 48 || key_code > 57)) {
      event.preventDefault();
    }
  }

  handleCashAmountChange(event) {
    let value = parseInt(event.target.value);
    let new_value = null;
    if (isNaN(value)) {
      new_value = ""
    } else if (value < 1) {
      new_value = 1;
    } else if (value > 2000) {
      new_value = 2000;
    } else {
      new_value = value;
    }
    const line_item = event.target.closest("li");
    event.target.value = new_value;
    if (event.target.name == "cash_amount") {
      Utility.updateContent({
        cash_gift_image_amount: "$" + new_value
      });
    } else {
      this.displayCashGiftTotal();
    }
    this.updateCashAmountOptions(line_item, new_value);
  }

  handleCashAmountOptionChange(event) {
    let cash_amount;
    if (event.target.classList.contains("--selected")) {
      cash_amount = "";
    } else {
      cash_amount = parseInt(
        event.target.getAttribute("data-cash-amount")
      );
    }
    const line_item = event.target.closest("li");
    let cash_amount_element = line_item.querySelector(
      `input[name^="cash_amount"]`
    );
    cash_amount_element.value = cash_amount;
    cash_amount_element.dispatchEvent(new Event("input"))
    if (cash_amount_element.name == "cash_amount") {
      Utility.updateContent({
        cash_gift_image_amount: "$" + cash_amount
      }
      );
    } else {
      this.displayCashGiftTotal();
    }
    this.updateCashAmountOptions(line_item, cash_amount);
  }

  updateCashAmountOptions(line_item, current_cash_amount) {
    line_item.querySelectorAll(".cash-amount-option").forEach(
      (element) => {
        let cash_amount = parseInt(
          element.getAttribute("data-cash-amount")
        );
        if (cash_amount == current_cash_amount) {
          element.classList.add("--selected");
        } else {
          element.classList.remove("--selected");
        }
      }
    );
  }

  handleCashGiftRecipPhoneKeyPress(event) {
    let key_code = event.which ? event.which : event.keyCode;
    if (
      key_code > 31 &&
      (key_code < 48 || (key_code > 57 && key_code != 189))
    ) {
      event.preventDefault();
    }
  }

  displayCashGiftTotal() {
    let total = 0.00;
    this.sendFlow.el.querySelectorAll(`input[id^="cash_amount"]`).forEach(
      (cash_amount_element) => {
        let cash_amount = parseFloat(cash_amount_element.value);
        if (!isNaN(cash_amount)) {
          total += cash_amount;
        }
      }
    );
    Utility.updateContent({
      "total-gift-amt": "$" + total.toFixed(0),
    }, this.sendFlow.el);
  }

  hydrateSelectSingleCashGift() {
    this.sendFlow.wizard.panels.get("select-gift")?.disable();
    if (this.sendFlow.order.delivery_method == "copy") {
      window.app_dialogs.close("gift-options");
      window.app_dialogs.close("share-send");
      this.sendFlow.wizard.panels.get("send-email")?.disable();
      Utility.updateContent({
        "share-phone-number-container": {
          removeClass: "hidden",
        }
      }, this.sendFlow.el);
    } else {
      Utility.updateContent({
        "share-phone-number-container": {
          addClass: "hidden",
        }
      }, this.sendFlow.el);
    }
    Utility.updateContent({
      "cash-gift-container": {
        addClass: "hidden",
      },
      "cash-amounts-container": {
        addClass: "hidden",
      },
      "cash-amount-container": {
        removeClass: "hidden",
      },
    }, this.sendFlow.el);
    this.sendFlow.wizard.panels.get("select-cash-gift").enable();
  }

  hydrateSelectMultipleCashGifts() {
    this.sendFlow.wizard.panels.get("select-gift").disable();
    Utility.updateContent({
      "share-phone-number-container": {
        addClass: "hidden",
      }
    }, this.sendFlow.el);

    let cash_recipients_content = "";
    this.sendFlow.order.recipients.forEach(
      (recipient, index) => {
        const template = this.sendFlow.el.querySelector(
          ".recip-cash-amount-template"
        );
        const template_vars = {
          email: recipient.email,
          name: recipient.name,
          index: index
        };
        cash_recipients_content += Render.interpolateTemplate(
          template, template_vars, true
        );
      }
    );
    Utility.updateContent({
      "recip-cash-amounts": cash_recipients_content
    });
    let form = this.sendFlow.forms.get("cash-amounts-form");
    form.initFields();
    form.el.querySelectorAll(`input[id^="cash_amount"]`).forEach(
      (cash_amount_element) => {
        cash_amount_element.addEventListener(
          "keypress", this.handleCashAmountKeyPress.bind(this)
        );
        cash_amount_element.addEventListener(
          "input", this.handleCashAmountChange.bind(this)
        );
      }
    );
    form.el.querySelectorAll(".cash-amount-option").forEach(
      (element) => {
        element.addEventListener(
          "click", this.handleCashAmountOptionChange.bind(this)
        );
      }
    );
    Utility.updateContent({
      "cash-gift-container": {
        addClass: "hidden",
      },
      "cash-amount-container": {
        addClass: "hidden",
      },
      "cash-amounts-container": {
        removeClass: "hidden",
      },
    }, this.sendFlow.el);
    this.sendFlow.wizard.panels.get("select-cash-gift").enable();
  }

  async savePendingCashGiftAmounts() {
    let cash_amounts = [];
    let form_name = `cash-amount${this.sendFlow.order.recipients.length > 1 ? "s" : ""}-form`;
    let form = this.sendFlow.forms.get(form_name);
    if (this.sendFlow.order.delivery_method == "copy") {
      let cash_amount_element = this.sendFlow.el.querySelector(
        `#${form_name} #cash-amount`
      );
      let cash_amount = parseFloat(cash_amount_element.value);
      cash_amounts.push(cash_amount);
    } else {
      this.sendFlow.order.recipients.forEach(
        (recipient, index) => {
          let amount_selector;
          if (this.sendFlow.order.recipients.length > 1) {
            amount_selector = `#${form_name} [name="cash_amount_${index}"]`
          } else {
            amount_selector = `#${form_name} [name="cash_amount"]`
          }
          let cash_amount_element = this.sendFlow.el.querySelector(
            amount_selector
          );
          let cash_amount = parseFloat(cash_amount_element.value);
          cash_amounts.push(cash_amount);
        }
      );
    }
    let data = {
      ecard_id: this.sendFlow.order.id,
      cash_amounts: cash_amounts,
    };
    let response = await fetch(
      "/api/gifts/save-pending-cash-gift-amounts", {
      method: "POST",
      body: JSON.stringify(data)
    });
    const response_json = await response.json();
    if (response_json.status == "success") {
      form.complete("custom");
      this.showBirdieInterface();
    }
  }

  showBirdieInterface() {
    let birdie_recipients = [];
    let external_id = `${this.sendFlow.order.id}${this.sendFlow.order.sender_id_fragment}`;
    if (this.sendFlow.order.delivery_method == "copy") {
      let cash_amount_element = this.sendFlow.el.querySelector(
        "#cash-amount-form #cash-amount"
      );
      let cash_amount = parseFloat(cash_amount_element.value) * 100;
      let recip_phone_element = this.sendFlow.el.querySelector(
        "#cash-amount-form #cash-gift-recipient-phone-number"
      );
      let recip_phone = recip_phone_element.value.replace(/\-/g, "");
      birdie_recipients.push({
        phone: "+1" + recip_phone,
        giftAmount: cash_amount,
        externalId: external_id,
      });
    } else {
      this.sendFlow.order.recipients.forEach(
        (recipient, index) => {
          let amount_selector;
          if (this.sendFlow.order.recipients.length > 1) {
            amount_selector = `#cash-amounts-form [name="cash_amount_${index}"]`
          } else {
            amount_selector = `#cash-amount-form [name="cash_amount"]`
          }
          let cash_amount_element = this.sendFlow.el.querySelector(
            amount_selector
          );
          let cash_amount = parseFloat(cash_amount_element.value) * 100;
          birdie_recipients.push({
            email: recipient.email,
            giftAmount: cash_amount,
            externalId: external_id,
          });
        }
      );
    }
    let birdie_user = {
      email: window.app_env.customer.email,
      firstName: window.app_env.customer.name,
      lastName: window.app_env.customer.lname
    };
    let prefilled_data = {
      recipients: birdie_recipients,
      user: birdie_user
    };
    if (this.cashGiftModule) {
      this.cashGiftModule.close();
    }
    this.cashGiftModule = BirdieGifting({
      clientId: this.cashGiftSettings.birdie_client_id,
      themeKey: "agi",
      prefilledData: prefilled_data,
      onGiftSend: (redemptionInfo) => {
        this.saveCashGiftRedemptionInfo(redemptionInfo);
      },
      onError: () => { },
      onClose: () => { this.handleCashGiftInterfaceDoneClicked(); },
      onCancel: () => { this.sendFlow.setFlowStep("select-cash-gift"); },
      onLoad: () => { },
    });
    Utility.updateContent({
      "cash-amount-container": {
        addClass: "hidden",
      },
      "cash-amounts-container": {
        addClass: "hidden",
      },
    }, this.sendFlow.el);
    this.cashGiftModule.render("#cash-gift-container");
    Utility.updateContent({
      "cash-gift-container": {
        removeClass: "hidden",
      },
    }, this.sendFlow.el);
  }

  handleCashGiftInterfaceDoneClicked() {
    if (this.cashGiftCompleted) {
      this.sendFlow.showConfirmPage();
    } else if (this.cashGiftTotal) {
      this.showCashGiftSpinner();
    } else {
      this.sendFlow.setFlowStep("select-cash-gift");
    }
  }

  showCashGiftSpinner() {
    let totalValidRecpients = (
      (this.sendFlow.order.recipients.length || 1) - this.cashGiftFailures.length
    );
    let title = `Finalizing your cash gift${totalValidRecpients > 1 ? "s" : ""}...`;
    let imageConfig = {
      image_icon_class: "hidden",
      image_src: "",
      image_alt: "",
      image_width: "10",
      image_height: "10",
    };
    this.cashGiftSpinner = new Spinner({
      image: "",
      trigger: null,
      spinner_title: title,
      status: { title },
      ...imageConfig
    }, true);

    this.cashGiftSpinner.dialog.isBlocking = true;
    this.cashGiftSpinner.update(
      { spinner__close: { addClass: "hidden" } }
    );
    this.cashGiftProcessingInterval = setInterval(() => {
      if (this.cashGiftCompleted) {
        this.cashGiftSpinner.completed();
        this.cashGiftSpinner.dialog.isBlocking = false;
        this.cashGiftSpinner.dialog.close();
        this.sendFlow.showConfirmPage();
        clearInterval(this.cashGiftProcessingInterval);
        return;
      }
    }, Utility.getRandomInt(100, 1000));
  }

  async saveCashGiftRedemptionInfo(redemption_info) {
    try {
      let redeem_urls = [];
      let partner_order_ids = [];
      let product_prices = [];
      let service_fees = [];
      let card_fees = [];

      this.cashGiftTotal = 0;
      this.cashGiftFailures = [];
      this.cashGiftCompleted = false;

      redemption_info.forEach(
        (item) => {
          if (item.transactionStatus == "Failed") {
            this.cashGiftFailures.push(item);
            return;
          }
          let redeem_url_item = {
            redeem_url: item.accessUrl
          };
          if (this.sendFlow.order.delivery_method == "copy") {
            redeem_url_item.phone = item.phone.replace("+1", "");
          } else {
            redeem_url_item.email = item.email;
          }
          redeem_urls.push(redeem_url_item);
          partner_order_ids.push(item.identifier);
          product_prices.push(
            item.giftAmount.replace("$", "")
          );
          service_fees.push(item.platformFee);
          card_fees.push(item.cardFee);
        }
      );
      product_prices.map(
        (gift_amount) => {
          this.cashGiftTotal += parseInt(gift_amount);
        }
      );
      if (!redeem_urls.length) {
        // we don't have any successful redemptions
        return;
      } else {
        this.sendFlow.wizard.panels.get("select-cash-gift").disableNavigation();
      }
      let data = {
        ecard_id: this.sendFlow.order.id,
        product_title: "Cash Gift",
        product_retailer_name: "Birdie",
        partner_order_ids: partner_order_ids,
        product_image: "https://images.contentstack.io/v3/assets/bltcbd0de6834ffd7a9/blt8d53f00743823e32/cash-gift-red.png",
        product_prices: product_prices,
        service_fees: service_fees,
        card_fees: card_fees,
        redeem_urls: redeem_urls,
        status: "success"
      };
      let response = await fetch(
        "/api/gifts/complete-cash-gift-transaction", {
        method: "POST",
        body: JSON.stringify(data)
      });
      if (response.status == 200) {
        let select_cash_gift_form = this.sendFlow.wizard.panels.get("select-cash-gift").form;
        select_cash_gift_form.fields.get("delivery_method").setValue(
          this.sendFlow.order.delivery_method
        );
        select_cash_gift_form.submit();
      }
    } catch (error) {
      alert("error saving cash gift redemption info");
    }
  }

  pruneFailedCashGiftRecipients() {
    if (this.cashGiftFailures.length) {
      let recipientsTable = window.app_tables.collection.get("recipients");
      // we have at least 1 failed redemption
      // for the multi-recipient email flow
      // so let's prune the bad recipients(s)
      let failed_emails = this.cashGiftFailures.map(
        (item) => { return item.email; }
      );
      let prune_filter = (item) => {
        return !failed_emails.includes(item.email);
      }
      this.sendFlow.order.recipients = this.sendFlow.order.recipients.filter(prune_filter);
      recipientsTable.data = recipientsTable.data.filter(prune_filter);
      recipientsTable.entries = recipientsTable.entries.filter(prune_filter);
      recipientsTable.renderItems(recipientsTable.entries);
    }
  }

  hydrateCashGiftFailures() {
    let cash_gift_failures_content = "";
    let failures_text = "";
    if (
      this.sendFlow.getGift() &&
      this.sendFlow.order.gift.name == "Cash Gift" &&
      this.cashGiftFailures.length
    ) {
      this.cashGiftFailures.forEach(
        (item) => {
          const template = this.sendFlow.el.querySelector(
            "template#cash-gift-failure"
          );
          const template_vars = {
            recipient_email: item.email,
          };
          cash_gift_failures_content += Render.interpolateTemplate(
            template, template_vars, true
          );
        }
      );
      failures_text = (
        this.cashGiftFailures.length > 1 ?
          "Multiple payment transactions have" :
          "A payment transaction has"
      );
    }
    Utility.updateContent({
      "cash-gift-failed-recips": cash_gift_failures_content,
      "cash-gift-transaction-failures-text": failures_text,
      "confirm-try-again": {
        "href": this.sendFlow.order.delivery_details.resend_url
      }
    });
    this.sendFlow.el.querySelector("#cash-gift-failures").style.display = (
      cash_gift_failures_content.length ? "" : "none"
    );
  }
}

export default CashGiftProvider;