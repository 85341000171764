import CashGiftProvider from "./CashGiftProvider";
import CustomSongProvider from "./CustomSongProvider";
import GiftCardProvider from "./GiftCardProvider";
import VirginExperienceProvider from "./VirginExperienceProvider";
import Utility from "../../../base/Utility";

class DigitalGiftsManager {

  constructor(sendFlow) {
    this.providers = new Map();
    this.giftOptions = window.app_env?.gift_options;
    this.sendFlow = sendFlow;
    this.emailForm = this.sendFlow.forms.get("email-send");
    this.shareForm = window.app_forms.collection.get("gift-options-form");
    this.constructors = {
      "giftcard": GiftCardProvider,
      "cash_gift": CashGiftProvider,
      "virgin_experience_gift": VirginExperienceProvider,
      "custom-song": CustomSongProvider,
    };
    this.giftActions = {
      "add": this.addDigitalGiftHandler.bind(this),
      "remove": this.removeDigitalGiftHandler.bind(this),
    }
    this.init();
  }

  init() {
    if(!this.giftOptions && this.sendFlow.order.mode == 'giftcard-first') {
      let Constructor = this.constructors['giftcard'];
      this.providers.set('giftcard', new Constructor('giftcard', this));
    } else {
      this.giftOptions.forEach((giftOption) => {
        if (!this.constructors.hasOwnProperty(giftOption.keyword)) return
        let Constructor = this.constructors[giftOption.keyword];
        this.providers.set(giftOption.keyword, new Constructor(giftOption, this));
      });
    }
    window.app_listeners?.add('click', 'digital-gift-click', this.clickHandler.bind(this))
  }

  clickHandler(e) {
    if (!e.target.getAttribute("data-digital-gift-action")) return
    let action = e.target.getAttribute("data-digital-gift-action");
    let giftOption = this.giftOptions.find(el => el.keyword === e.target.value);
    if(action in this.giftActions) {
      this.giftActions[action](giftOption.keyword, e);
    }
  }

  addDigitalGiftHandler(giftKey, event) {
    if(giftKey != "custom-song") {
      this.emailForm?.fields.get(giftKey)?.setValue(true, true);
      this.shareForm?.fields.get(giftKey)?.setValue(true, true);
    }
    this.providers.get(giftKey).addGiftAttached(event);
  }

  removeDigitalGiftHandler(giftKey){
    this.emailForm?.fields.get(giftKey)?.setValue(false, true);
    this.shareForm?.fields.get(giftKey)?.setValue(false, true);
    if(giftKey == "custom-song") {
      this.providers.get(giftKey)?.customSongClient?.report("deletedsong")
    }
    this.sendFlow.hydrateDeliveryDetails();
  }

  initGiftProvider(config) {
    let sendForm = this.sendFlow.forms.get("email-send");
    if (sendForm) {
      let giftField = sendForm.fields.get(config.keyword);
      if (!giftField) return
      this.updateDeliveryDetails(giftField, sendForm);
    }
    let shareForm = window.app_forms.collection.get("gift-options-form");
    if (shareForm && config.can_be_shared) {
      let giftField = shareForm.fields.get(config.keyword);
      if (!giftField) return
      this.updateDeliveryDetails(giftField, shareForm);
    }
  }

  updateDeliveryDetails(giftField, form) {
    let secondaryForm = null;
    if(form.id == "gift-options-form" ) {
      secondaryForm = this.sendFlow.forms.get("email-send");
      this.hydrateGiftOptionsSubmit();
    } else if (form.id == "email-send") {
      secondaryForm = window.app_forms.collection.get("gift-options-form");
      this.sendFlow.hydrateDeliveryDetails();
    }
    giftField.onChange = (input) => {
      this.sendFlow.deliveryDetails[
        `select_${input.name}`
      ] = input.checked;
      if (secondaryForm?.fields?.get(input.name)) {
        secondaryForm.fields.get(input.name).setValue(input.checked, false);
      }
      if (input.checked) {
        this.giftOptions.forEach(
          (secondaryGiftOption) => {
            if (secondaryGiftOption.keyword != input.name) {
              let secondaryGiftField = form.fields.get(secondaryGiftOption.keyword);
              if(secondaryGiftField) {
                secondaryGiftField.setValue(false, true);
              }
              if (secondaryForm?.fields?.get(secondaryGiftOption.keyword)) {
                secondaryForm.fields.get(secondaryGiftOption.keyword).setValue(false, false);
              }
              this.sendFlow.deliveryDetails[
                `select_${secondaryGiftOption.keyword}`
              ] = false;
            }
          }
        );
      }
      if(form.id == "gift-options-form" ) {
        this.hydrateGiftOptionsSubmit();
      } else if (form.id == "email-send" && input.name != "custom-song") {
        this.sendFlow.hydrateDeliveryDetails();
      }
    };
  }

  removeGiftAttached() {
    this.giftOptions.forEach((giftOption) => {
      Utility.updateContent({
        [`${giftOption.keyword}-option`]: {
          removeClass: "hidden",
          addClass: "--border",
        },
      })
    });
    if (!this.providers.get("custom-song")?.customSongClient) {
      Utility.updateContent({
        "custom-song-option": {
          addClass: "hidden"
        }
      });
    }
    let canSchedule = !(["RFU", "REG"].includes(window.app_env?.customer.status) || window.app_env?.customer.is_in_collections || window.app_env?.customer.is_in_trial);
    let sendEmailDescription = canSchedule ? "Add recipient(s) for your greeting, customize your name, send now or schedule up to a year in advance." : "Select or create recipient(s) for your greeting and customize your name.";
    Utility.updateContent({
      digital_gifts_title: "Add a Digital Gift",
      gift_attached: {
        addClass: "hidden",
      },
      add_digital_gift: {
        removeClass: "hidden",
      },
      remove_digital_gift: {
        addClass: "hidden",
      },
      "send-email-description": sendEmailDescription,
      "hide-on-lock": {
        inner: "You can schedule up to a year in advance",
        removeClass: canSchedule ? "hidden" : "",
        addClass: !canSchedule ? "hidden" : ""
      },
      "cash-gift-instructions": {
        addClass: "hidden",
        removeClass: "--primary-lock-reason"
      },
    });
    let datePicker = this.sendFlow.forms.get("email-send").fields.get("delivery_date");
    if (datePicker && datePicker.isInit && !window.app_env.customer.is_in_trial) {
      datePicker.setMaxDate();
      datePicker.lock(false);
    }
    this.sendFlow.checkFutureDate(datePicker?.getLocalValue());
    this.sendFlow.order.personalizations.gift = {};
    this.sendFlow.order.gift = {};
    if(this.sendFlow.order.features_used.includes("used_songfinch_gift")) {
      let index = this.sendFlow.order.features_used.indexOf("used_songfinch_gift");
      this.sendFlow.order.features_used.splice(index, 1);
      this.sendFlow.saveFlow();
    }
  }

  hasPaidGift() {
    let gift = this.sendFlow.getGift();
    return Boolean(gift && (this.sendFlow.deliveryDetails["select_custom-song"] ? gift.name == "Custom Song" : gift))
  }

  addGiftAttached(gift) {
    this.giftOptions.forEach((giftOption) => {
      Utility.updateContent({
        [`${giftOption.keyword}-option`]: {
          addClass: "hidden",
        },
      });
    });
    Utility.updateContent({
      digital_gifts_title: "Digital Gift Selected ",
      gift_selection: {
        removeClass: "hidden",
      },
      add_digital_gift: {
        addClass: "hidden",
      },
      [`${gift}-option`]: {
        removeClass: ["hidden", "--border"],
      },
    });
    if (this.sendFlow.order.mode != "edit" || !this.hasPaidGift()) {
      Utility.updateContent({
        remove_digital_gift: {
          removeClass: "hidden",
        },
      });
    }
    if (this.sendFlow.order.mode == "edit" && this.hasPaidGift()) {
      Utility.updateContent({
        info_digital_gift: {
          addClass: "hidden",
        },
      });
    }
  }

  hydrateGiftOptionsSubmit() {
    let form = this.sendFlow.forms.get("gift-options-form");
    if (!form) return
    let isGiftOptionSelected = false;
    let shareCustomSong = this.sendFlow.deliveryDetails["select_custom-song"];
    form.fields.forEach(
      (field) => {
        if (field.el.checked) {
          isGiftOptionSelected = true;
        }
      }
    );
    const getGiftOptionsSubmitLabel = () => {
      let action = this.sendFlow.isResend ? "Resend" : "Share";
      if (isGiftOptionSelected && !shareCustomSong) {
        return `Add Gift & ${action}`
      } else if (shareCustomSong) {
        return `Checkout & ${action}`
      } else {
        return `${action} without gift`
      }
    }

    Utility.updateContent({
      share_greeting_submit_label: getGiftOptionsSubmitLabel(),
      share_greeting_submit: {
        addClass: isGiftOptionSelected && !shareCustomSong ? "--has-gift" : "",
        removeClass: !(isGiftOptionSelected && !shareCustomSong) ? "--has-gift" : "",
        "aria-label": getGiftOptionsSubmitLabel(),
      },
    });

    this.giftOptions.forEach((giftOption) => {
      if (giftOption.can_be_shared && this.sendFlow.deliveryDetails[`select_${giftOption.keyword}`] && !shareCustomSong) {
        this.providers.get(giftOption.keyword).addGiftAttached(this.sendFlow.order.personalizations.gift);
      }
    });

    if (!isGiftOptionSelected) {
      this.removeGiftAttached();
    }
  }

  hydrateGift() {
    this.sendFlow.order.gift = this.sendFlow.getGift();
    let gift = this.sendFlow.order.gift;
    if (gift) {
      this.sendFlow.el.classList.add("--has-gift")
    } else {
      this.sendFlow.el.classList.remove("--has-gift")
    }
    let cash_gift_amount = "";
    if (gift?.name == "Cash Gift") {
      cash_gift_amount = "$" + (this.providers.get("cash_gift").cashGiftTotal || gift.price);
    }
    Utility.updateContent({
      "gift_img": {
        "data-src": gift ? gift.image_url : "",
        "alt": gift ? gift.title : "",
        "title": gift ? gift.title : ""
      },
      "gift_description": gift ? gift.title : "",
      "cash_gift_amount": {
        inner: cash_gift_amount,
        removeClass: cash_gift_amount ? "hidden" : "",
        addClass: !cash_gift_amount ? "hidden" : "",
      },
    });
  }

  getDeliveryDetailGift() {
    let giftSelected = false;
    this.giftOptions.forEach((giftOption) => {
      if (this.sendFlow.deliveryDetails[`select_${giftOption.keyword}`]) {
        giftSelected = giftOption
        return
      }
    });
    return giftSelected;
  }

  getAttachedGift() {
    let giftSelected = false;
    this.giftOptions.forEach((giftOption) => {
      if (this.sendFlow.getGift() && this.sendFlow.order.gift.name == giftOption.description) {
        giftSelected = giftOption
        return
      }
    });
    return giftSelected
  }
}

export default DigitalGiftsManager;
