import Heartbeat from "@common/modules/utilities/Heartbeat";
import Render from "../../../base/Render";
import Utility from "../../../base/Utility";

class VirginExperienceProvider {
  constructor(config, manager) {
    this.manager = manager;
    this.config = config;
    this.giftOption = config.keyword;
    this.label = config.description;
    this.sendFlow = manager.sendFlow;
    this.init();
  }

  init() {
    this.manager.initGiftProvider(this.config);
    this.initVirginExperienceGiftPanel();
  }

  addGiftAttached() {
    this.manager.addGiftAttached(this.giftOption);
    if(this.sendFlow.order.mode == "edit" && this.sendFlow.order.gift){
      Utility.updateContent({
        "gift_description": this.sendFlow.order.gift.description,
      })
    }
    if (this.sendFlow.order.mode != 'edit') {
      let datePicker = this.sendFlow.forms.get("email-send").fields.get("delivery_date");
      if (datePicker && datePicker.isInit && !datePicker.locked) {
        Utility.updateContent({
          "send-email-description": `Add recipient(s) for your greeting, customize your name, send now or schedule up to 6 months in advance.`,
          "hide-on-lock": "You can schedule up to 6 months in advance",
        });
        datePicker.setMaxDate(183);
        this.sendFlow.checkFutureDate(datePicker.getLocalValue());
      }
    }
  }

  initVirginExperienceGiftPanel() {
    let form = this.sendFlow.forms.get("select-virgin-experience-gift-form");
    let panel = this.sendFlow.wizard.panels.get("select-virgin-experience-gift");
    let iframeHolder = panel?.el.querySelector("#virgin-merchant");
    if (!panel || !form || !iframeHolder) return
    let virginListener = new AbortController();

    let heartbeat = null;
    let iframeString = iframeHolder.innerHTML || "";
    this.renderIframeVirginExperience(form, panel, iframeHolder, virginListener, heartbeat, iframeString);
  }

  renderIframeVirginExperience(form, panel, iframeHolder, virginListener, heartbeat, iframeString) {
    // Render iframe before panel activation
    panel.el.addEventListener("panel-before-activation", () => {
      virginListener?.abort();
      virginListener = new AbortController();
      iframeHolder.innerHTML = "";
      iframeHolder.appendChild(Render.fragmentFromString(iframeString));
      let iframe = iframeHolder.querySelector("iframe");
      let loader = iframeHolder.querySelector(".lazy-load-iframe__loader");
      if (!iframe) return
      iframe.setAttribute("src", this.sendFlow.order.giftcard_iframe);
      iframe.addEventListener("load", () => {
        setTimeout(() => {
          iframeHolder.classList.add("--loaded");
          setTimeout(() => {
            loader.remove();
          }, 300)
        }, 500);
      });
      // Listen for message event
      window.addEventListener('message', e => {
        if (!e.origin.includes("virginexperiencegifts.com") || e.data?.event != "success") return
        panel.disableNavigation();
        heartbeat?.kill();
        heartbeat = new Heartbeat(30, 15);
        heartbeat.onSuccess = (data) => {
          form.el.submit();
        }
        heartbeat.onFail = (reason) => {
          form.addError("server", "Unable to connect to merchant, please try again later.");
          form.renderErrors();
          panel.enableNavigation();
        }
        heartbeat.start(`${app_env.shost}/gifts/transaction-started/${this.sendFlow.order.id}${this.sendFlow.order.sender_id_fragment}`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      }, { signal: virginListener.signal });
    });

    // Clear iframe and errors after deactivation
    panel.el.addEventListener("panel-deactivated", () => {
      virginListener?.abort();
      form.clearErrors(["server"]);
      heartbeat?.kill();
      iframeHolder.classList.remove("--loaded");
      iframeHolder.innerHTML = "";
    });
  }
}

export default VirginExperienceProvider;