import Helpers from "../../base/Helpers";
import Render from "../../base/Render";
import Utility from "../../base/Utility";

class MailboxFilter {
  constructor(el) {
    this.el = el;
    this.type = "mailbox";
    this.searchParams = new URLSearchParams();
    this.form = el.getInstance("forms") || null;
    this.allowedSequences = ["delivdate", "cancel", "name", "email", "reset", "init", "next-page", "submit"];
    this.sequence = null;
    this.cardsHolder = document.querySelector('.myaccount-cards');
    this.templates = {
      card: Render.getTemplateString(document, "mailbox-card"),
    };
    this.emptyBlock = document.querySelector('.empty-response-wrapper');
    this.resetButton = this.el.querySelector('.filters__reset');
    this.filterButton = this.el.querySelector('.filters__filter');
    this.activeValues = new Map();
    this.loadedOptions = new Map();
    this.loadedCards = null;
    this.filters = new Map();
    this.currentPage = 1;
    this.processors = {
      email: this.processEmails.bind(this),
      delivdate: this.processDates.bind(this),
      name: this.processNames.bind(this),
    };
    this.config = window.app_env?.mailbox_config || {};
    this.paginationWrapper = document.querySelector('.load-more') || null;
    this.isCopyingLink = false;
    this.init();
  }

  init() {
    this.initCancelSend();
    this.form.overrideSubmitHandler = () => {
      this.filter("submit");
    };
    this.form.fields.forEach((field, key) => {
      this.filters.set(key, field);
    })
    this.filter("init");
    let resetFilter = document.querySelector(".filter__reset");
    resetFilter.addEventListener("click", this.clickHandler.bind(this))
    window.app_listeners.add("click", "filters-click", this.clickHandler.bind(this));
  }

  initCancelSend() {
    let cancelForm = window.app_forms?.collection.get('ag-cancel-send-form') || null;
    let cancelConfirmForm = window.app_forms?.collection.get('ag-cancel-confirm-form') || null;
    if (!cancelForm || !cancelConfirmForm) return
    cancelForm.onSuccess = () => {
      this.resetFilters();
      this.filter("init");
      return true;
    }
    cancelConfirmForm.onSuccess = () => {
      this.resetFilters();
      this.filter("init");
      return true;
    }
  }

  clickHandler(e) {
    let target = e.real_target || e.target;
    if (target?.classList.contains("view-full-message")) {
      return this.toggleMessage(target);
    }
    if(target?.classList.contains("copy_link_button")) {
      return this.copyLink(target);
    }
    if(target?.classList.contains("filter__reset")) {
      this.resetFilters();
      this.filter("reset");
    }
    if (target == this.resetButton) {
      this.resetFilters();
      this.filter("reset");
    }
  }

  resetFilters(resetKeys = ["email", "delivdate", "name"]) {
    if (resetKeys.includes("email")) {
      this.resetButton?.classList.add("hidden");
      this.filterButton?.classList.remove("hidden");
    }
    this.filters.forEach((filter, key) => {
      if (!resetKeys.includes(key)) return
      filter.loadOptions(this.loadedOptions.get(key));
      let value = filter.key === 'email' ? '' : 'All';
      this.activeValues.set(filter.key, value);
      filter.setValue(value);
      filter.lastValue = value;
    });
  }

  initFilters(filtersData) {
    this.filters.forEach((field, key) => {
      let process = this.processors[key];
      let processed = process(filtersData);
      field.loadOptions(processed);
      this.loadedOptions.set(key, processed);
      this.activeValues.set(key, field.getValue());
      field.onSelect = (label, value) => {
        if (this.activeValues.get(key) == value) return
        if (field.key == "email") {
          this.resetFilters(["delivdate", "name"]);
        }
        this.activeValues.set(key, value);
        this.filter(key);
      }
    })
  }

  async buildDataRequest(endpoint, item) {
    const response = await fetch(endpoint);
    if (!response?.headers?.get("content-type")?.includes("application/json")) {
      throw new Error(`invalid response for ${item}`)
    }
    const data = await response.json();
    if (!data?.response) throw new Error(`unformatted response for ${item}`)
    return data.response
  }

  alterFilterParams(params) {
    if (
      this.sequence == "delivdate"
      && (params.has("platform_code") || params.has("name"))
    ) {
      params.delete("name");
      params.delete("platform_code");
      params.delete("delivery_method");
    }
    if (this.sequence == "name" && params.has("delivdate")) {
      params.delete("delivdate");
    }
    return params
  }

  async getData(items = ["cards", "filters"]) {
    let requests = [];
    let params = new URLSearchParams(this.searchParams.toString());
    items.forEach(item => {
      let endpoint = new URL(item == "cards" ? this.config.cards_url : this.config.filters_url);
      if (item == "filters") {
        endpoint.search = this.alterFilterParams(params).toString();
      } else {
        endpoint.search = params.toString();
      }
      requests.push(this.buildDataRequest(endpoint, item))
    })
    try {
      let responses = await Promise.all(requests);
      return responses
    } catch (e) {
      this.handleGetDataError(e);
      return null
    }
  }

  handleGetDataError(e) {
    this.setLoading(false);
    this.form.addError(
      "server",
      `Could not retrieve your ${this.config.flow} cards at this time,
      please try again later, if the problem persists contact us.`
    );
    this.form.renderErrors();
  }

  setLoading(loading = true) {
    if (this.sequence == "next-page") {
      document.querySelector(".load-more__trigger")?.classList.toggle('--loading', loading);
    } else {
      this.form.el.classList.toggle('--loading', loading);
      if (!loading) {
        this.form.el.classList.add("--loading-complete");
        setTimeout(() => {
          this.form.el.classList.remove("--loading-complete");
        }, 200);
      } else {
        setTimeout(() => {
          this.form.el.querySelector(".form__loading-inner")?.focus();
        });
      }
    }
  }

  async filter(sequence) {
    this.form.removeErrors();
    if (!this.allowedSequences.includes(sequence)) return
    this.sequence = sequence;
    let lastSearchParams = this.searchParams?.toString() || "";
    this.buildSearchParams();
    if ("init" != this.sequence && lastSearchParams == this.searchParams.toString()) return
    this.setLoading();

    if ("init" == this.sequence) {
      let response = await this.getData();
      if (!response) return this.setLoading(false)
      let [cardsData, filtersData] = response;
      this.loadedCards = cardsData;
      this.initFilters(filtersData);
      this.hydrateCards(cardsData);
      return this.setLoading(false)
    }

    // Here reset sequence will fall also
    if (this.searchParams.size == 0) {
      this.resetFilters();
      this.hydrateCards(this.loadedCards);
      return this.setLoading(false)
    }

    if ("next-page" == this.sequence) {
      let response = await this.getData(["cards"]);
      if (!response) return this.setLoading(false)
      let [cardsData] = response;
      this.hydrateCards(cardsData);
      return this.setLoading(false)
    }

    let response = await this.getData();
    if (!response) return this.setLoading(false)
    let [cardsData, filtersData] = response;
    this.hydrateFilters(filtersData);
    this.hydrateCards(cardsData);
    return this.setLoading(false)
  }

  buildSearchParams() {
    if (["init", "reset"].includes(this.sequence)) {
      this.searchParams = new URLSearchParams();
      return
    }

    if ("next-page" == this.sequence) {
      this.searchParams.set("current_page", this.currentPage + 1);
      return
    }

    let params = new URLSearchParams();
    this.filters.forEach(filter => {
      let value = filter.getValue();
      if (["All", ""].includes(value)) return
      if (filter.key == "name" && value.includes("=")) {
        value.split("&").forEach(pair => {
          let values = pair.split("=");
          params.set(values[0], values[1]);
        })
        return
      }
      params.set(filter.key, value);
    })
    this.searchParams = params;
  }

  processNames(filterData) {
    if (!filterData) return {};
    if (!this.nameAsMethods) {
      this.nameAsMethods = filterData.methods?.some(m => filterData.names.includes(m));
    }
    let processed = [{ All: "All" }];
    let methods = {};
    let names = {};
    filterData.methods.forEach(method => {
      let key = this.nameAsMethods ? `${method} (method)` : method;
      methods[key] = this.config.methods[method];
    })
    filterData.names.forEach(name => {
      let parsedName = Render.html(name);
      names[parsedName] = `name=${parsedName}`;
    })
    Object.keys(methods).length && processed.push(methods);
    Object.keys(names).length && processed.push(names);
    return processed;
  }

  processDates(filterData) {
    if (!filterData) return {};
    let processed = [{ All: "All" }];
    let dates = filterData.dates.reduce((accumulator, current) => {
      if (isNaN(Date.parse(current))) {
        accumulator[current] = current;
      } else {
        let date = new Date(current);
        let key = date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
        let value = date.toLocaleString('en-US', { month: 'numeric', year: 'numeric' });
        accumulator[key] = value;
      }
      return accumulator;
    }, {});
    Object.keys(dates).length && processed.push(dates);
    return processed
  }

  processEmails(filterData) {
    if (!filterData) return {};
    return filterData?.emails?.reduce((accumulator, current) => {
      accumulator[current] = current;
      return accumulator;
    }, {}) || {};
  }

  hydrateFilters(filterData) {
    if ("name" != this.sequence) {
      let names = this.processNames(filterData);
      this.filters.get("name").loadOptions(names);
    }
    if ("delivdate" != this.sequence) {
      let dates = this.processDates(filterData);
      this.filters.get("delivdate").loadOptions(dates);
    }
    this.resetButton?.classList.toggle("hidden", !this.searchParams.has("email"));
    this.filterButton?.classList.toggle("hidden", this.searchParams.has("email"));
  }

  hydrateCards(cardData) {
    let renderString = "";
    this.currentPage = cardData.current_page ?? 1;
    this.pages = cardData.pages ?? 1;
    this.totalCards = cardData.numrecs ?? 0;
    const isLastPage = this.currentPage == this.pages;
    const isFirstPage = this.currentPage === 1;
    this.paginationWrapper?.classList.toggle('hidden', !this.totalCards || isLastPage);
    this.emptyBlock.classList.toggle('hidden', this.totalCards != 0);
    cardData.results?.forEach(item => {
      renderString += this.renderCard(item);
    })
    if (isFirstPage) {
      this.cardsHolder.innerHTML = "";
    }
    this.cardsHolder.appendChild(Utility.fragmentFromString(renderString));
    window.app_images?.init(this.cardsHolder);
  }

  renderCard(item) {
    // This method will be refactored for AG-9393
    const messageMaxChars = 255;
    let inner = `${this.templates.card}`;
    let prodThumb = item.prod_thumb;
    let prodTitle = item.prod_title;
    let isRFU = ["REG", "RFU"].includes(window.app_env.customer.real_status);
    let isCollections = window.app_env.customer.is_in_collections;
    let canSend = item.canSend;
    let receivedDate = Helpers.convertDateTimeFromServer(item.received_date);
    receivedDate = receivedDate.toLocaleString('en-US', { month: 'long', year: 'numeric', day: 'numeric' });
    let recipientName = item.recipient.name;
    let recipientEmail = item.recipient.email;
    let pickupStatus = item.recipient.pickup_status;
    let message = item.message ? new DOMParser().parseFromString(item.message, 'text/html').body.textContent : '';
    let resendLink = item.recipient.resend_link;
    let resendLinkText = item.recipient.resend_link_text;
    let viewLink = item.view_link;
    let cancelLink = item.recipient.cancel_link;
    let editLink = item.recipient.edit_link;
    let gifts = Object.keys(item.gifts).length > 0 ? item.gifts : false;
    let editWrapper = "";
    let resendWrapper = "";
    let cancelWrapper = "";
    let viewWrapper = "";
    let copyLinkWrapper = "";
    let canResend = true;

    if (gifts) {
      canResend = !pickupStatus.includes("Picked Up") && item.delivery_method == "EMAIL";
    }
    if (canResend && isRFU && !canSend) {
      resendWrapper = `<div class="myaccount-info__resend"><button class="btn-simple dialog-trigger" aria-haspop="dialog" aria-controls="rfu-resend" aria-label="${resendLinkText.toLowerCase()} ${prodTitle}"><div class="btn__text">${resendLinkText}</div></button></div>`;
      editWrapper = `<div class="myaccount-info__edit"><button class="btn-simple dialog-trigger" aria-haspop="dialog" aria-controls="rfu-resend" aria-label="edit ${prodTitle}"><div class="btn__text">Edit</div></button></div>`;
    }
    else if (canResend && isCollections && !canSend) {
      resendWrapper = `<div class="myaccount-info__resend"><button class="btn-simple dialog-trigger" aria-haspop="dialog" aria-controls="collections-dialog" aria-label="${resendLinkText.toLowerCase()} ${prodTitle}"><div class="btn__text">${resendLinkText}</div></button></div>`;
      editWrapper = `<div class="myaccount-info__edit"><button class="btn-simple dialog-trigger" aria-haspop="dialog" aria-controls="collections-dialog" aria-label="edit ${prodTitle}"><div class="btn__text">Edit</div></button></div>`;
    }
    else if (resendLink && item.delivery_method != 'PRINT') {
      resendWrapper = `<div class="myaccount-info__resend"><a href="${resendLink}" class="btn-simple" aria-label="${resendLinkText.toLowerCase()} ${prodTitle}">${resendLinkText}</a></div>`;
    }
    else if (editLink) {
      editWrapper = `<div class="myaccount-info__edit"><a href="${editLink}" class="btn-simple" aria-label="edit ${prodTitle}">Edit</a></div>`;
    }
    if (cancelLink) {
      cancelWrapper = `<div class="myaccount-info__cancel"><button class="btn-simple myaccount-cancel-btn" {gift_attached} data-cancel-send="${cancelLink}" {scheduled_confirmation} aria-label="cancel ${prodTitle}" ><div class="btn__text">Cancel</div></button></div>`;
    }
    if (viewLink) {
      viewWrapper = `<div class="myaccount-image__view"><a href="${viewLink}" class=" btn-simple" aria-label="view ${prodTitle}">View</a></div>`;
    }
    inner = inner.replace(/{cancelLink}/g, cancelWrapper !== "" ? cancelWrapper : "");
    if (item.recipient.scheduled_confirmation) {
      inner = inner.replace(/{scheduled_confirmation}/g, "data-requires-confirmation");
    }
    else {
      inner = inner.replace(/{scheduled_confirmation}/g, "");
    }
    let badgeClass = "";
    if (pickupStatus == "Awaiting Pick Up" || pickupStatus == "Scheduled") {
       badgeClass = "sent";
    } else if (pickupStatus.includes("Picked Up")) {
      badgeClass = "viewed";
    } else if (pickupStatus == "Email Not Found") {
      badgeClass = "bounced";
    }

    inner = inner.replace(/{title}/g, prodTitle);
    inner = inner.replace(/{prodThumb}/g, prodThumb);
    inner = inner.replace(/{status}/g, `<span class="status-badge --${badgeClass}">${pickupStatus}</span>`);

    if (this.config.flow == 'received') {
      inner = inner.replace(/{recipient}/g, `<div class="myaccount-info__recipient"><p>From</p><p class="myaccount-info__recipient-name" data-cs-mask><span class="--dark-text">${recipientEmail}</span> (${recipientName})</p></div>`);
      inner = inner.replace(/{date}/g, `<div><svg width="14" height="12"><use href='#received-icon'></use></svg><span>Received on ${receivedDate}</span></div>`);
    } else {
      switch (item.platform_code) {
        case 'MOBILEAPP':
          if (item.delivery_method !== 'EMAIL') {
            inner = inner.replace(/{recipient}/g, `<div class="myaccount-info__recipient"><p>Recipient</p><p><span class="--dark-text">Mobile</span></p></div>`);
          } else {
            inner = inner.replace(/{recipient}/g, `<div class="myaccount-info__recipient"><p>Recipient</p><p class="myaccount-info__recipient-name" data-cs-mask><span class="--dark-text">${recipientEmail}</span> (${recipientName})</p></div>`);
          }
          break;
        case 'WEBSITE':
          if (item.delivery_method == 'EMAIL') {
            inner = inner.replace(/{recipient}/g, `<div class="myaccount-info__recipient"><p>Recipient</p><p class="myaccount-info__recipient-name" data-cs-mask><span class="--dark-text">${recipientEmail}</span> (${recipientName})</p></div>`);
          }

          if (item.delivery_method == 'COPY') {
            inner = inner.replace(/{recipient}/g, `<div class="myaccount-info__recipient"><p>Recipient</p><p><span class="--dark-text">Shared by link</span></p></div>`);
            copyLinkWrapper = `<div class="myaccount-image__edit"><button data-url="${item.pickup_link}" class="btn-simple copy_link_button" aria-label="copy link ${prodTitle}">Copy Link</button></div>`;
            inner = inner.replace(/{date}/g, `${this.config.flow == "scheduled" ? "<div><svg width='16' height='16'><use href='#schedule-icon'></use></svg> <span>Scheduled for" : "<div><svg width='16' height='16'><use href='#send-icon'></use></svg> <span>Shared on"} ${receivedDate}</span></div>`);
          }

          if (item.delivery_method == 'SHARE') {
            inner = inner.replace(/{recipient}/g, `<div class="myaccount-info__recipient"><p>Recipient</p><p><span class="--dark-text">Shared by Facebook</span></p></div>`);
            inner = inner.replace(/{date}/g, `${this.config.flow == "scheduled" ? "<div><svg width='16' height='16'><use href='#schedule-icon'></use></svg> <span>Scheduled for" : "<div><svg width='16' height='16'><use href='#send-icon'></use></svg> <span>Shared on"} ${receivedDate}</span></div>`);
          }

          if (item.delivery_method == 'PRINT') {
            inner = inner.replace(/{recipient}/g, `<div class="myaccount-info__recipient"><p>Recipient</p><p><span class="--dark-text">Printed</span></p></div>`);
            inner = inner.replace(/{status}/g, "");
            inner = inner.replace(/{date}/g, "");
          }

          break;
      }
      inner = inner.replace(/{date}/g, `${this.config.flow == "scheduled" ? "<div><svg width='16' height='16'><use href='#schedule-icon'></use></svg> <span>Scheduled for" : "<div><svg width='16' height='16'><use href='#send-icon'></use></svg> <span>Sent on"} ${receivedDate}</span></div>`);
    }
    if (message != '') {
      if (message.length <= messageMaxChars) {
        inner = inner.replace(/{message}/g, `<div class="myaccount-info__head hidden-xs"><p>Message:</p></div><div class="myaccount-info__body"><p><span class="inline-visible-xs">Message:</span> ${message}</p></div>`);
      } else {
        let truncatedMessage = message.substring(0, messageMaxChars);
        let remainingMessage = message.substring(messageMaxChars);
        inner = inner.replace(/{message}/g, `<div class="myaccount-info__head hidden-xs"><p>Message:</p></div><div class="myaccount-info__body"><p tabindex="-1"><span class="inline-visible-xs">Message:</span> ${truncatedMessage}<span class="hidden remaining-msg">${remainingMessage}</span><span class="suspension-dots">...</span> <button type="button" class="btn-simple view-full-message" aria-label="view full message">View full message</button></p></div>`);
      }
    } else {
      inner = inner.replace(/{message}/g, '');
    }

    let actionWrappers = [viewWrapper];
    switch (this.config.flow) {
      case "sent":
        actionWrappers = [resendWrapper, copyLinkWrapper, viewWrapper];
        break;
      case "scheduled":
        actionWrappers = [viewWrapper, editWrapper, cancelWrapper];
        break;
    }
    let actionLinks = actionWrappers.filter(
      (wrapper) => {return wrapper !== "";}
    ).join(`<div class="ui-vertical-divider"></div>`);

    inner = inner.replace(/{actionLinks}/g, actionLinks);

    if (gifts) {
      inner = inner.replace(/{gifts}/g, `<div><svg width='16' height='16'><use href='#gift-icon'></use> <span>${gifts.description} Included</span></div>`);
      inner = inner.replace(/{gift_attached}/g, `data-gift-attached=${gifts.type}`);
      inner = inner.replace(/{gifts_description}/g, `${gifts.description}`);
      if (gifts.description == 'Custom Song') {
        inner = inner.replace(/{show_gift}/g, '--custom-song');
      }
      if(gifts.gift_amount) {
        inner = inner.replace(/{giftAmount}/g, `<p class="myaccount-image__gift-amount">$${gifts.gift_amount}</p>`);
      } else {
        inner = inner.replace(/{giftAmount}/g, "");
      }
      if (gifts.image_url) {
        inner = inner.replace(/{giftThumb}/g, gifts.image_url);
      }
    } else {
      inner = inner.replace(/{gifts}/g, '');
      inner = inner.replace(/{gift_attached}/g, '');
      inner = inner.replace(/{show_gift}/g, 'hidden');
    }
    return inner;
  }

  toggleMessage(button) {
    let suspensionDots = button.parentElement.querySelector('.suspension-dots');
    let remainingMessage = button.parentElement.querySelector('.remaining-msg');
    if (button.classList.contains('--hide-msg')) {
      button.classList.remove('--hide-msg');
      button.setAttribute('aria-label', 'view full message');
      button.textContent = 'View full message';
      remainingMessage.classList.add('hidden');
      suspensionDots.classList.remove('hidden');
    } else {
      button.classList.add('--hide-msg');
      button.setAttribute('aria-label', 'hide full message');
      button.textContent = 'Hide full message';
      remainingMessage.classList.remove('hidden');
      suspensionDots.classList.add('hidden');
    }
  }

  copyLink(button) {
    if (this.isCopyingLink) return
    let copyLink = window.app_env.shost + button.getAttribute("data-url");
    this.isCopyingLink = true;
    const initialText = button.textContent;
    const manageInnerCopy = () => {
      button.textContent = "Copied!";
      setTimeout(() => {
        this.isCopyingLink = false;
        button.textContent = initialText;
      }, 1000);
    }
    const copyFallback = () => {
      Utility.copyFallback(copyLink);
      manageInnerCopy();
    }
    if (navigator.clipboard) {
      navigator.clipboard.writeText(copyLink).then(manageInnerCopy, copyFallback);
    } else {
      copyFallback();
    }
  }
}

export default MailboxFilter;