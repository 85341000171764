import Client from "../../base/Client";
import Utility from "../../base/Utility";
import Spinner from "../Spinner";
import Pickup from "./Pickup";
import BaseProduct from "./detail/BaseProduct";
import CreatacardProduct from "./detail/CreatacardProduct";
import EcardProduct from "./detail/EcardProduct";
import InteractiveProduct from "./detail/InteractiveProduct";
import SelfieSmashupProduct from "./detail/SelfieSmashupProduct";
import SmashupProduct from "./detail/SmashupProduct";
import TalkingSmashupProduct from "./detail/TalkingSmashupProduct";

class Products {
  constructor() {
    this.product = null;
    this.pickup = null;
    this.abortController = null;
    this.favorites = window.app_env?.favorites_ids || [];
    this.quickviewController = null;
    this.flags = [
      // "url-quickviews"
    ];
    this.quickview = null;
    this.productControllerMap = {
      'ecard': EcardProduct,
      'interactive': InteractiveProduct,
      'creatacard': CreatacardProduct,
      'smashup': SmashupProduct,
      'talking-smashup': TalkingSmashupProduct,
      'selfie-smashup': SelfieSmashupProduct,
      'fallback': BaseProduct,
    }
    this.init();
  }

  init() {
    window.app_listeners?.add(
      "click",
      "products",
      this.clickHandler.bind(this)
    );
    window.app_listeners?.add(
      "afterClick",
      "products",
      this.afterClickHandler.bind(this)
    );
    let pickupEl = document.querySelector(".product-pickup");
    if (pickupEl) {
      this.pickup = new Pickup(pickupEl, this);
    }
    this.recoverQuickview();
    this.initProduct();
  }

  initProduct() {
    if (window.app_env?.page_type && window.app_env.page_type != "pdp") return
    let productEl = document.querySelector('.product-detail');
    let ProductClass = this.productControllerMap['fallback'];
    if (productEl && productEl.hasAttribute('data-id')) {
      this.product?.destroy();
      let type = productEl.getAttribute('data-type') || 'fallback';
      if (this.productControllerMap.hasOwnProperty(type)) {
        ProductClass = this.productControllerMap[type] || ProductClass;
      } else {
        type = 'fallback';
      }
      productEl.setAttribute('data-type', type);
      this.product = new ProductClass(productEl, this, type);
    }
  }

  recoverQuickview() {
    if (!this.flags.includes("url-quickviews")) return
    const params = new URLSearchParams(window.location.search);
    if (params.has("quickview")) {
      setTimeout(() => {
        this.loadQuickview(null, params.get("quickview"));
      }, 500);
    }
  }

  clickHandler(e) {
    let btn = e.real_target || e.target;

    if (btn.classList.contains("dialog-trigger") && btn.hasAttribute("data-product-id")) {
      this.loadQuickview(e);
    } else if (btn.classList.contains("add-to-favorites")) {
      if (window.app_env?.is_signed_in) {
        this.toggleFavorite(btn);
      } else {
        let dialog = window.app_dialogs?.open("signin-dialog");
        if (!dialog) return
        Utility.updateContent({ "signin-dialog-title": "Want to save to Favorites? Sign In!" })
        dialog.onAfterClose = () => {
          setTimeout(() => {
            Utility.updateContent({ "signin-dialog-title": "Welcome to American Greetings!" })
          }, 100);
        }
      }
    } else if (btn.hasAttribute("data-cancel-send")) {
      this.promptCancelSend(btn.getAttribute("data-cancel-send"), btn.getAttribute("data-gift-attached"), btn.hasAttribute("data-requires-confirmation"));
    } else if (btn.hasAttribute("data-giftcard-send")) {
      this.initGiftcardSend(btn);
    }
  }

  afterClickHandler(e) {
    let btn = e.real_target || e.target;
    if (btn.hasAttribute("data-slide-giftcard-send")) {
      this.initGiftcardSend(btn);
    }
  }

  async initGiftcardSend(btn) {
    const path = btn.getAttribute("data-url");
    let store = "";
    if(Utility.isValidUrl(path)) {
      window.location = path;
      return
    }
    let url = new URL(`${window.location.origin}${path}`);
    if (url.searchParams.has("store")) {
      store = url.searchParams.get("store");
    }
    let imgEl = btn.querySelector("img");
    let title = "Preparing gift card send...";
    let errorTitle = "Could not start your send, please try again later.";
    let imageConfig = {
      giftcard_icon_class: "",
      image_icon_class: "hidden",
      image_src: "",
      image_alt: "",
      image_width: "10",
      image_height: "10",
    };
    if (store) {
      title = "Preparing your gift card...";
      errorTitle = "Could not send this gift, please try again later.";
      imageConfig = {
        giftcard_icon_class: "hidden",
        image_icon_class: "",
        image_src: imgEl?.getAttribute("data-src") || "",
        image_alt: imgEl?.getAttribute("alt") || "",
        image_width: imgEl?.getAttribute("width") || "",
        image_height: imgEl?.getAttribute("height") || "",
      }
    }
    let spinner = new Spinner({
      image: imgEl?.outerHTML || "",
      trigger: btn,
      spinner_title: title,
      status: { title },
      ...imageConfig
    }, true);
    let abortController = new AbortController();
    spinner.onAbort = () => {
      abortController.abort();
    }
    try {
      let request = await fetch("/gift-cards/send", {
        method: "POST",
        signal: abortController.signal,
        body: Utility.objectToFormData({ store }),
        headers: {
          'X-Csrftoken': Client.getCSRFToken(),
        }
      })
      let response = await request.json();
      if (response.status == "success") {
        spinner.completed({
          title: "Redirecting..."
        })
        setTimeout(() => {
          window.location = response.data.redirect_url;
        });
      }

    } catch (error) {
      if (abortController.signal.aborted) return
      spinner.update({
        state: "error",
        title: errorTitle,
        spinner__title: {
          inner: errorTitle,
          "aria-live": "assertive",
        },
        spinner__actions: {
          removeClass: "hidden"
        },
        spinner__container: {
          addClass: "hidden"
        }
      })
    }

  }

  updateFavoriteButtons(id, added = true) {
    const buttons = document.querySelectorAll(`.add-to-favorites[data-product-id="${id}"]`);
    let title = null;
    let keyword = added ? "Saved" : "Not saved";
    let copy = added ? "Saved" : "Save";
    buttons.forEach(button => {
      if (!title) {
        title = button.getAttribute('data-product-title');
      }
      if (added) {
        button.classList.add('--is-favorite')
      } else {
        button.classList.remove('--is-favorite');
      }
      button.setAttribute('aria-label', `${keyword}, ${title} to favorites.`);
      let innerText = button.querySelector(".btn-inline-simple__text");
      if (innerText) {
        innerText.innerText = `${copy} to Favorites`;
      }
    });
  }

  updateFavoritesBadge() {
    const count = window.app_env?.favorites_count || 0;
    const favoritesBadge = document.querySelector('.account-favorites__counter');
    const favoritesBtn = document.querySelector('.account-favorites');
    if (count == 0) {
      favoritesBadge.textContent = 0;
      favoritesBtn.classList.remove('--has-items');
      favoritesBtn.setAttribute('aria-label', 'No favorites items');
    } else {
      favoritesBadge.textContent = count > 99 ? "99+" : count;
      favoritesBtn.classList.add('--has-items');
      let label = count > 1 ? `Favorites, one item` : `Favorites, ${count} items`;
      favoritesBtn.setAttribute('aria-label', label);
    }
  }

  async toggleFavorite(btn) {
    const id = Number(btn.getAttribute("data-product-id"));
    this.abortController?.abort();
    this.abortController = new AbortController();
    if (!window?.app_env?.favorites_ids) return
    let add = app_env.favorites_ids.indexOf(id) == -1;
    const endpoint = `/myaccount/favorites/${add ? "add/ecards" : "del"}/${id}`;
    try {
      btn.classList.add('--loading');
      const response = await fetch(endpoint, { signal: this.abortController.signal });
      if (response.status != 200) throw 0;
      if (add) {
        window.app_env.favorites_ids.push?.(id);
        window.app_env.favorites_count++;
        window.app_accessibility.announce({message: "Saved to favorites"});
      } else {
        window.app_env.favorites_ids.removeItem?.(id);
        window.app_env.favorites_count--;
        window.app_accessibility.announce({message: "Removed from favorites"});
      }
      this.updateFavoritesBadge();
      this.updateFavoriteButtons(id, add);
    } catch (e) {
      console.warn(e);
    } finally {
      btn.classList.add('--loaded');
      setTimeout(() => {
        btn.classList.remove('--loading');
        btn.classList.remove('--loaded');
      }, 300);
    }
  }

  promptCancelSend(endpoint, gift, hasConfirmation) {
    let dialog = window.app_dialogs?.collection.get("cancel-scheduled-dialog");
    let confirm_dialog = window.app_dialogs?.collection.get("cancel-scheduled-confirm");
    let form = window.app_forms?.collection.get("ag-cancel-send-form");
    if (!dialog || !endpoint || !form) return
    form.el.setAttribute('data-endpoint', endpoint);
    Utility.updateContent({
      "gift-attached-copy": {
        removeClass: gift ? "hidden" : "",
        addClass: !gift ? "hidden" : "",
      }
    });
    if(['jifiti', 'virgin-experience'].includes(gift)) {
      Utility.updateContent({
        "gift-email-address": {
          "inner": gift == "jifiti" ? "customercare@jifiti.com" : "info@virginexperiencegifts.com",
          "href": gift == "jifiti" ? "mailto:customercare@jifiti.com" : "mailto:info@virginexperiencegifts.com",
        }
      }, dialog.el);
    }
    if(hasConfirmation) {
      Utility.updateContent({
        "submit-cancel": {
          addClass: "hidden",
        }
      }, dialog.el);
      confirm_dialog?.el.querySelector("#ag-cancel-confirm-form").setAttribute("data-endpoint", endpoint);
    } else {
      Utility.updateContent({
        "confirm-cancel": {
          addClass: "hidden",
        }
      }, dialog.el);
    }
    window.app_dialogs?.open(dialog.name)
  }

  async buildProductQuickview(id) {
    const quickviewUrl = new URL(`${window.location.origin}/quickview/${id}`);
    const signal = this.quickviewController.signal;
    if (window.app_env?.favorites_ids?.includes(Number(id))) {
      quickviewUrl.searchParams.append("favorite", 1);
    }
    if (Client.supportsApplePay()) {
      quickviewUrl.searchParams.append("applepay", 1);
    }
    try {
      let response = await fetch(quickviewUrl, {signal});
      if (response.status != 200) throw "";
      let content = await response?.text();
      if (!content) throw "";
      return content
    } catch (e) {
      return null
    }
  }

  async loadQuickview(e = null, id = null) {
    let trigger = e?.target || e?.real_target || document.querySelector(`button[data-product-id="${id}"]`);
    if (e && !trigger.hasAttribute("data-product-id")) return;
    const product_number = trigger?.getAttribute("data-product-id") || id;
    if (!product_number) return
    this.quickviewController?.abort();
    this.quickviewController = new AbortController();
    trigger?.classList.remove("--loaded");
    trigger?.classList.add("--loading");

    const content = await this.buildProductQuickview(product_number);
    if (content) {
      const dialog = window.app_dialogs?.spawn(
        `product-quickview-${product_number}`,
        {template: "product-quickview", content},
        true,
        trigger
      );
      setTimeout(() => {
        this.initQuickview(dialog);
      }, 300);
    }
    trigger?.classList.add("--loaded");
    setTimeout(() => {
      if (trigger?.classList.contains("--loaded")) {
        trigger?.classList.remove("--loading", "--loaded");
      }
    }, 0)
  }

  initQuickview(dialog) {
    let productEl = dialog.el.querySelector('.product-detail');
    if (!productEl || !productEl.hasAttribute('data-id')) return
    let productEnv = JSON.parse(dialog.el.querySelector(".product_json")?.textContent || "{}") || {};
    let mergedEnv = {...window.app_env, ...productEnv};
    let ProductClass = this.productControllerMap['fallback'];
    dialog.el.setAttribute("aria-label", productEnv.product.title);
    window.app_services?.track("product-quickview-opened", productEnv.product);
    let type = productEl.getAttribute('data-type') || 'fallback';
    ProductClass = this.productControllerMap[type] || this.productControllerMap["fallback"];
    productEl.setAttribute('data-type', type);

    // Creatacard Fix to be able to display multiple instances of card3d on page
    let isExclusiveProduct = "creatacard" == type && "creatacard" == this.product?.type;
    isExclusiveProduct && this.product.destroy()


    // Add url param
    if (this.flags.includes("url-quickviews")) {
      let url = new URL(window.location.href);
      if (!url.searchParams.has("quickview")) {
        url.searchParams.set('quickview', productEnv.product.product_number);
        window.history.replaceState(window.app_storage?.get("state") || {}, document.title, url);
      }
    }

    dialog?.el.addEventListener("dialog-after-close", () => {
      this.quickview?.destroy();
      if (isExclusiveProduct) {
        this.product = null;
        setTimeout(() => {
          this.initProduct();
        }, 300)
      }
      if (this.flags.includes("url-quickviews")) {
        let url = new URL(window.location.href);
        if (url.searchParams.has("quickview")) {
          url.searchParams.delete('quickview');
          window.history.replaceState(window.app_storage?.get("state") || {}, document.title, url);
        }
      }
    });
    setTimeout(() => {
      this.quickview = new ProductClass(productEl, this, type, mergedEnv);
    }, 300)
  }
}

export default Products;
