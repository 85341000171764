import Client from "../../../base/Client";
import Helpers from "../../../base/Helpers";
import Utility from "../../../base/Utility";

class Preview {
  constructor(el, product, pers) {
    this.el = el;
    this.env = window.app_env;
    this.product = product;
    this.raw_pers = pers;
    this.order = this.env?.ecard_order;
    this.config = this.env?.product_config;
    this.pers = null;
    this.dialog = this.el.closest(".dialog")?.getInstance("dialogs");
    this.isReady = false;
    this.productBuilded = false;
    this.productIsBuilding = false;
    this.productInterface = null;
    this.container = this.el.querySelector('.product-preview__content');
    this.placer = this.container.querySelector(".product-html-div")
    this.messageEl = this.el.querySelector('.product-preview__message');
    this.hasVideo = false;
    this.videoEl = null;
    this.type = product?.model || "fallback";
    this.focusableList = ['[role="button"]', '[role="slider"]', '[role="menuitemradio"]', '[role="menuitem"]', '.jw-icon-volume', '#jwPlayerWrapper'];
    this.ignoredFocusables = ['.jw-nextup-close','.jw-horizontal-volume-container'];
    this.player = null;
    // Hooks
    this.onReady = () => { };
    this.init();
  }

  setProductDimensions(dimensions) {
    if (!dimensions?.length || dimensions.length != 2) return
    if (typeof dimensions[0] != "number" || typeof dimensions[1] != "number") return
    this.el.style.setProperty("--product-aspect-ratio", `${dimensions[0]}/${dimensions[1]}`)
    this.el.style.setProperty("--product-reverse-ratio", `${dimensions[1] / dimensions[0]}`)
  }

  init() {
    this.beforeInit();

    window.app_listeners?.add('pageshow', 'stop-product-music-upon-bfcache', this.pageshowHandler.bind(this));
    this.dialog?.el.addEventListener(
      "dialog-opened",
      this.onDialogOpen.bind(this)
    );
    this.dialog?.el.addEventListener(
      "dialog-closed",
      this.onDialogClose.bind(this)
    );

    this.afterInit();
  }

  async loadProduct() {
    if (!window.agi?.ProductLoader) return console.log("no agi-product-loader library found!");
    if (!this.env.product_config.loader_options) return console.log("no loader config!");

    const options = this.env.product_config.loader_options;
    options.personalizations = await this.loadPersonalizations();
    const product_number = this.product.product_number;
    this.loader = new agi.ProductLoader(product_number, options);
    this.loader.load().then(product => {
      this.productInterface = product;
      this.initListeners();
      this.placer?.appendChild(product.domNode);
      return product.onAddedToDom()
    }).then(product => {
      this.afterLoad();
    });
  }


  async loadPersonalizations() {
    if (this.pers) return this.pers
    let lobsterId = null;
    let persObject = this.raw_pers;
    const lobsterKeys = ["StorageId", "storageId", "storage_id", "LobsterId", "lobsterId", "lobster_id"];
    if (this.raw_pers?.NETA) {
      persObject = JSON.parse(decodeURIComponent(this.raw_pers.NETA));
    }
    lobsterKeys.forEach(key => {
      if (persObject[key]) {
        lobsterId = persObject[key];
      }
    })
    if (lobsterId) {
      try {
        persObject = await window.app_services?.lobster.load(this.product.product_number, lobsterId);
      } catch (error) {
        console.warn("could not load customizations", error);
      }
    }
    this.pers = persObject || {};
    return persObject || {}
  }

  pageshowHandler(e) {
    if (e.persisted && !this.dialog?.isActive && this.isReady) {
      setTimeout(this.pauseMedia.bind(this), 0);
    }
  }

  ready() {
    this.isReady = true;
    this.productBuilded = true;
    this.productIsBuilding = false;
    this.el.classList.add('--init', '--ready');
    this.onReady();
    Helpers.initExpressionResizeWatcher(this.container, this.type);
    Client.dispatchEvent('preview-ready', {}, this.el);
    this.afterReady();
    setTimeout(() => {
      this.el.classList.add('--after-ready');
    }, 300);
  }

  setMessage(htmlString = '') {
    if (!this.messageEl) return
    if (htmlString.trim().length == 0) {
      this.messageEl.innerHTML = '';
      this.messageEl.classList.add('--empty');
      if (this.dialog) {
        Utility.updateContent({
          'edit-trigger': {
            'inner': `<span class="btn__text"> Add Message </span>`,
            'aria-label': `Add message`,
          }
        }, this.dialog.el);
      }
    } else {
      this.messageEl.innerHTML = htmlString;
      this.messageEl.classList.remove('--empty');
      if (this.dialog) {
        Utility.updateContent({
          'edit-trigger': {
            'inner': `<span class="btn__text"> Edit Message </span>`,
            'aria-label': `Edit message`,
          }
        }, this.dialog.el)
      }
    }
  }

  onDialogClose(e) {
    this.pauseMedia();
    setTimeout(() => this.adaptFocusables(e.detail, false), 0);
    setTimeout(() => {
      this.resetMedia();
    }, 300)
  }

  onDialogOpen(e) {
    window.dispatchEvent(new Event("resize"));
    this.playMedia();
    setTimeout(() => this.adaptFocusables(e.detail), 0);
  }

  adaptFocusables(dialog, adapt = true) {
    if (dialog) {
      dialog.el.querySelector('.product-preview__content')?.querySelectorAll(this.focusableList.join(', ')).forEach(el => {
        if (adapt) {
          el.setAttribute('tabindex', '0');
        } else {
          el.removeAttribute('tabindex');
        }
      })
      dialog.el.querySelector('.product-preview__content')?.querySelectorAll(this.ignoredFocusables.join(', ')).forEach(el => {
        el.setAttribute('tabindex', '-1');
      })
    }
  }

  // Common Methods For Inheritance
  beforeInit() { }
  afterInit() {
    this.ready();
  }
  initListeners() { }
  afterLoad() { }
  afterReady() { }
  pauseMedia() { }
  resetMedia() { }
  playMedia() { }

}

export default Preview;