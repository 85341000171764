import Client from "../base/Client";
import Render from "../base/Render";
import Utility from "../base/Utility";
import Dialog from "./Dialog";

class Dialogs {

  constructor() {
    this.activeDialogs = new Map();
    this.collection = new Map();
    this.dialogSpeed = 200;
    this.dialogsWrapper = document.querySelector('.dialogs');
    this.aside = document.querySelector('.aside') || null;
    this.init();
  }

  init() {
    const els = document.querySelectorAll('.dialog, .aside');
    this.initAside();
    if (!els || els.length == 0) return
    els.forEach(this.initDialog.bind(this));
    window.app_listeners?.add('focus', 'dialogs-focus-handler', this.focusHandler.bind(this));
    window.app_listeners?.add('keydown', 'dialogs-exit', this.keydownHandler.bind(this));
    window.app_listeners?.add('click', 'dialog-click-handler', this.clickHandler.bind(this));
  }

  keydownHandler(e) {
    if (e.key != 'Escape') return;
    if (document.activeElement.tagName == "INPUT" && document.activeElement.type == 'search' && document.activeElement.value != '') return
    const item = document.querySelector('.dialog[open]:not(.--sidelined), .aside[open]:not(.--sidelined)');
    if (!item) return
    const name = item.id
    window.app_dialogs?.deactivate(name, e);
  }

  focusHandler(e) {
    const active = document.activeElement;
    if (active.classList.contains('proxy-trigger-keyboard') || active == document.body || document.fullscreenElement) return
    const isInDialog = active.closest('.dialog[open]:not(.--sidelined), .aside[open]:not(.--sidelined)');
    if (isInDialog || (active.classList.contains('dialog') && !active.classList.contains('--sidelined'))) {
      this.collection.get(isInDialog?.id)?.activate();
    }
  }

  activate(name, trigger, e = null, staggerTransitions = false) {
    return this.open(name, trigger, e, staggerTransitions);
  }

  deactivate(name, e = null) {
    return this.close(name, e);
  }

  open(name, trigger, e = null, staggerTransitions = false) {
    let dialog = this.collection.get(name);
    dialog?.open(trigger, e, staggerTransitions);
    return dialog
  }

  close(name, e = null, forced = false) {
    let dialog = this.collection.get(name);
    dialog?.close(e, forced);
    return dialog
  }

  initDialog(el = null) {
    if (!el) return
    if (el.id == '') {
      el.id = `dialog-instance`;
    }
    Utility.fixDuplicates(el);
    this.collection.set(el.id, new Dialog(el, this));
  }

  clickHandler(e) {
    let target = e.real_target || e.target;
    if (!target.classList.contains('dialog-trigger') || !target.hasAttribute('aria-controls')) return
    const name = target.getAttribute('aria-controls') || target.getAttribute('href');
    const autofocus = document.querySelector(`#${name.replace('#', '')} input.autofocus`);
    if (name && autofocus && Client.isAppleHandheldDevice()) {
      setTimeout(() => {
        Client.showSoftKeyboard(autofocus);
      }, this.dialogSpeed + 100)
    }
    setTimeout(() => {
      this.open(name.replace('#', ''), target, e)
    }, 0);
    if (e && e.type == 'click') e.preventDefault();
  }

  spawn(id, options, open = false, trigger) {
    if (!this.dialogsWrapper) return
    if (this.collection.has(id)) {
      if (open) setTimeout(() => this.open(id, trigger), 0);
      return this.collection.get(id);
    }
    let templateQuery = options.template ? `.dialog-template-${options.template}` : '.dialog-template';
    const template = this.dialogsWrapper.querySelector(templateQuery);
    if (!template) return
    this.dialogsWrapper.appendChild(Render.interpolateTemplate(template, {
      "dialog-id": id,
      ...options
    }, false, false));
    const renderedDialog = this.dialogsWrapper.querySelector(`#${id}`);
    if (!renderedDialog) return
    renderedDialog.querySelectorAll(".dynamic-height").forEach(el => {
      Utility.addResizeObserver(el, null, '--dh-height');
    })
    renderedDialog.querySelectorAll(".form").forEach(formEl => {
      window.app_forms?.initForm(formEl);
    });
    window.app_accessibility?.ariaHiddenElInit(renderedDialog);
    this.initDialog(renderedDialog);
    if (open) setTimeout(() => this.open(id, trigger), 0);
    return this.collection.get(id);
  }

  adjustAsideForMobile(isMobile) {
    if (isMobile) {
      this.aside.setAttribute('aria-hidden', true);
      this.aside.style.display = 'none';
      setTimeout(() => {
        this.aside.style.display = '';
      }, 0)
      this.aside.setAttribute('role', 'dialog');
    } else {
      if (window.app_dialogs?.activeDialogs.has('aside')) {
        window.app_dialogs.deactivate('aside');
      }
      this.aside.removeAttribute('role');
      setTimeout(() => this.aside.setAttribute('aria-hidden', false), 0);
    }
  }

  initAside() {
    if (!this.aside) return
    window.app_listeners.add('isMobile', 'aside-mobile-switch', this.adjustAsideForMobile.bind(this, true));
    window.app_listeners.add('isDesktop', 'aside-desktop-switch', this.adjustAsideForMobile.bind(this, false));
  }
}

export default Dialogs;