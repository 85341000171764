import Utility from "../../../base/Utility";
import CustomSongClient from "../../product/CustomSongClient";

class CustomSongProvider {
  constructor(config, manager) {
    this.manager = manager;
    this.config = config;
    this.giftOption = config.keyword;
    this.label = config.description;
    this.sendFlow = manager.sendFlow;
    this.customSongClient = null;
    this.init();
  }

  init() {
    if (app_env.product_config?.custom_song && this.sendFlow.order.product.model != "creatacard"
      && !(["confirm-email", "confirm-share"].includes(this.sendFlow.order.current_step))) {
      this.customSongClient = new CustomSongClient(this.sendFlow);
    }
    if (!this.customSongClient) {
      Utility.updateContent({
        "custom-song-option": {
          addClass: "hidden"
        }
      });
      return
    }
    this.manager.initGiftProvider(this.config);
    this.customSongClient.onComplete = this.updateGiftAttached.bind(this);
  }

  updateGiftAttached(response) {
    if(!this.sendFlow.order.features_used.includes("used_songfinch_gift")) {
      let form = this.sendFlow.forms.get("email-send");
      if (this.sendFlow.order.current_step == "personalize") {
        form = window.app_forms.collection.get("gift-options-form");
      }
      form.fields.get(this.giftOption).setValue(true, true);
      this.sendFlow.order.personalizations.gift = response;
      this.sendFlow.order.gift = response;
      this.sendFlow.order.features_used.push("used_songfinch_gift");
      this.customSongClient?.report("selectedsong");
      this.sendFlow.saveFlow();
    }
    this.manager.addGiftAttached(this.giftOption);
    Utility.updateContent({
      gift_img: {
        "data-src": response?.selectedSong?.image_url,
        alt: response?.selectedSong?.title,
        addClass: "--square",
      },
      gift_attached: {
        removeClass: "hidden",
      },
      gift_img_styling: {
        addClass: "--square",
      },
      gift_title: response?.selectedSong?.artist,
      gift_description: response?.selectedSong?.title,
    });
    let image = window.app_dialogs.collection.get("gift-options")?.el.querySelector(".lazy-load");
    Utility.loadImage(image, true);
    if (this.sendFlow.order.current_step == "personalize" && this.sendFlow.order.delivery_method == "copy") {
      Utility.updateContent({
        remove_digital_gift: {
          "data-form": "gift-options-form",
        },
      });
    } else {
      Utility.updateContent({
        remove_digital_gift: {
          "data-form": "email-send",
        },
      });
    }
    if (this.sendFlow.order.product.model == "creatacard") {
      Utility.updateContent({
        remove_digital_gift: {
          addClass: "hidden",
        },
        info_digital_gift: {
          addClass: "hidden",
        },
      });
    }
    if (this.sendFlow.order.steps.includes("checkout")) {
      Utility.updateContent({
        gift_price: {
          removeClass: "hidden",
          "inner": `$${app_env.checkout_price}`,
        }
      });
      let datePicker = this.sendFlow.forms.get("email-send").fields.get("delivery_date");
      if (datePicker && datePicker.isInit && !datePicker.locked) {
        Utility.updateContent({
          "send-email-description": `Add recipient(s) for your greeting, customize your name, send now or schedule up to 30 days in advance.`,
          "hide-on-lock": "You can schedule up to 30 days in advance",
        });
        datePicker.setMaxDate(30);
        this.sendFlow.checkFutureDate(datePicker.getLocalValue());
      }
    }
  }

  addGiftAttached(event) {
    if (!this.sendFlow.order.features_used.includes("used_songfinch_gift")) {
      this.customSongClient.open(event?.real_target || event?.target);
    } else {
      this.updateGiftAttached(this.sendFlow.order.personalizations.gift);
    }
  }
}

export default CustomSongProvider;