import Utility from "../../base/Utility";

class DigitalGiftsFilters {
  constructor() {
    this.digitalGiftsWrapper = document.querySelector('.digital-gifts-filter');
    this.digitalGiftsLabels = [];
    this.digitalGiftsFilters = this.digitalGiftsWrapper.querySelectorAll("input[name='table-tab']");
    this.init();
  }

  init() {
    this.digitalGiftsFilters.forEach((filter) => {
      this.handleEmpty(filter);
      this.tableFilters(filter);
    });
  }

  tableFilters(filter) {
    filter.addEventListener("click", (e) => {
      if (e.target.value == "show_all") {
        this.digitalGiftsLabels.forEach((filterName) => {
          Utility.updateContent({
            [`${filterName}-table`]: {
              removeClass: "hidden",
            },
          });
        });
      } else {
        this.digitalGiftsLabels.forEach((filterName) => {
          Utility.updateContent({
            [`${filterName}-table`]: {
              addClass: "hidden",
            },
          });
        });
        Utility.updateContent({
          [`${e.target.value}-table`]: {
            removeClass: "hidden",
          },
        });
      }
    });
    filter.addEventListener("focus", (e) => {
      let target = e.real_target || e.target;
      const ul = target.closest("ul");
      const li = target.closest("li");
      const offset = getComputedStyle(ul)?.getPropertyValue('padding-left');
      target.closest("ul")?.scrollTo({
        left: li.offsetLeft - Number(offset.replace("px", "")),
        behavior: "smooth"
      })
    });
  }

  handleEmpty(filter) {
    Utility.elementRendered(`#${filter.value}.--table-rendered`).then(() => {
      let tableElement = window.app_tables.collection.get(filter.value);
      if (tableElement.entries.length == 0) {
        Utility.updateContent({
          [`${filter.value}-table`]: {
            addClass: "hidden",
          },
          [`${filter.value}-filter`]: {
            addClass: "hidden",
          },
        });
        if(filter.value == "cash_gift" && this.digitalGiftsLabels.length == 0) {
          Utility.updateContent({
             ["empty-digital-gifts"]: {
              removeClass: "hidden",
            },
          });
        }
      }
      else {
        this.digitalGiftsLabels.push(filter.value);
        let input = this.digitalGiftsWrapper.querySelector(`input[value='${filter.value}']`);
        input.removeAttribute("disabled");
      }
    });
  }
}

export default DigitalGiftsFilters;
